import React from 'react'
import * as css from '../../../CSS/main.css'

type MediaModalProps = {
    urlImage: string,
    isShowModalMedia: boolean
    setShowModalMedia?: (isShow: boolean, url: string | null)=> void
}

export function MediaModal(props: MediaModalProps): JSX.Element {

    return (
        <>
            {props.isShowModalMedia &&
                <div className={css.Overlay}>
                    <img className={css.ModalImage} src={props.urlImage} alt='' onClick={() => props.setShowModalMedia(false, null)} />
                </div>
            }
        </>
    )
}