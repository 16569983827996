import React from 'react'
import Popover from '../Popover'
import styles from '../../../CSS/main.css'
import Button, { ButtonIcon } from '../Button/Button'
import classNames from 'classnames'

type Props = {
  readonly open: boolean
  readonly onClose: () => void
  readonly children?: any
}

const ModalLarge: React.FC<Props> = ({ open, onClose, children }) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      backdropClassNames={classNames([
        styles.ButtonCoverDelete,
        styles.CoverDark,
      ])}
      backdropStyle={{ display: 'block' }}>
      <div
        className={classNames([styles.Confirmation, styles.ConfirmationLarge])}
        style={{
          display: 'block',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <div className={styles.Content}>
          <div className={styles.MenuText}>{children}</div>
        </div>
      </div>
    </Popover>
  )
}

export default ModalLarge
