import React from 'react'
import Portal from '../Portal'
import useMediaQuery from '../../utils/useMediaQuery'

type Props = {
  readonly backdropClassNames?: string
  readonly backdropStyle?: React.CSSProperties
  readonly open: boolean
  readonly onClose: (e: React.MouseEvent) => void
  readonly keepMounted?: boolean
}

const Popover: React.FC<Props> = ({
  children,
  backdropClassNames,
  backdropStyle,
  open,
  onClose,
  keepMounted,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  return keepMounted || open ? (
    <Portal>
      {children}
      {open && (
        <div
          onClick={onClose}
          onWheel={!isMobile ? onClose : undefined}
          className={backdropClassNames}
          style={backdropStyle}
        />
      )}
    </Portal>
  ) : null
}

export default Popover
