import React, { useCallback, useState } from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import Input from '../Input'

type Props = {
  readonly filters: (onClose: () => void) => JSX.Element
  readonly searchTerm: string
  readonly onChangeSearch: (newTerm: string) => void
}

const SearchBox: React.VFC = () => {
  const [textSearch, setTextSearch] = useState<string>('')

  return (
    <div className={styles.SearchNav}>
      <div className={styles.SearchBox}>
        <div className={classNames([styles.Search])}>
         <Input
            name={'Search'}
            placeholder={'Search'}
            value={textSearch}
            onChange={(text) => { setTextSearch(text) }}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchBox
