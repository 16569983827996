import React, {useContext, useEffect, useState} from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import {Col} from '../List/Col'
import WelcomeMessage from './WelcomeMessage'
import UserBlock from './UserBlock'
import useDimensions from 'react-cool-dimensions'
import {DimensionsContext} from '../../utils/dimensions-context'
import {PortalType, Slot} from '../Portal'
import {getMe} from "../../api/requests";
import {useAsync} from "react-async-hook";
import SearchBox from '../Search/SearchBox'
import { User } from '../../types'
import { Routes } from '../../routing'
import { useNavigation } from '../../utils/navigation'

const useHeaderState = () => {
  const fetchUser = useAsync<User>(getMe, [])

  return {
    user: fetchUser.result,
  }
}

type HeaderState = {
  unreadCount?: number,
  setOpenSettingModal: () => void
}

const Header: React.VFC<HeaderState> = ({ unreadCount, setOpenSettingModal }) => {
  const { observe, height, unobserve } = useDimensions<HTMLDivElement>({
    useBorderBoxSize: true,
  })
  const { update } = useContext(DimensionsContext)
  const { user } = useHeaderState()
  const [tab, setTab] = useState(document.location.pathname)
  const { goToNotParams } = useNavigation()

  const redirectNotificationsTab = () => {
    if (tab.toLocaleLowerCase() !== Routes.Notifications) {
      goToNotParams(Routes.Notifications)
    }
  }

  useEffect(() => {
    update({ headerHeight: height })
  }, [height])

  useEffect(() => {
    const cleanup = () => {
      unobserve()
    }
    
    return cleanup
  }, [])


  return (
    <div className={styles.TopUI} ref={observe}>
      <WelcomeMessage />
      <div className={styles.Navigation}>
        <div className={classNames([styles.Table, styles.NavTable])}>
          <Col additionalClassNames={[styles.NavMenu]}>
            <Slot name={PortalType.Burger} />
          </Col>
          <Col additionalClassNames={[styles.NavLogo]}>
            <img className={classNames([styles.IconLogo])} />
          </Col>
          <Col additionalClassNames={[styles.NavInfo]}><SearchBox /></Col>
          <Col additionalClassNames={[styles.NavBell]}>
          {tab.toLocaleLowerCase() === Routes.Notifications 
              ? 
                <div onClick={redirectNotificationsTab} className={Boolean(unreadCount) ? styles.ProfileIconNotificationsActiveBlue : styles.ProfileIconNotificationsBlue} />
              :
                <div onClick={redirectNotificationsTab} className={Boolean(unreadCount) ? styles.ProfileIconNotificationsActive : styles.ProfileIconNotifications} />
            }
          </Col>
          {Boolean(user) && <UserBlock user={user} setOpenSettingModal={setOpenSettingModal}/>}
        </div>
      </div>
    </div>
  )
}

export default Header
