import React from 'react'
import { Col, ColInfo} from '../Col'
import styles from '../../../../CSS/main.css'
import { useNavigation } from '../../../utils/navigation'
import { CommentTemplateData, SchnackAction } from '../../../types'
import ListItem, { BaseProps } from './ListItem'
import { ButtonIconNoBackGround } from '../../Button/Button'
import Dropdown from '../../Dropdown'
import moment from 'moment'

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
  Paused = 'paused',
}

export enum AccountType {
  User = 'user',
  Moderator = 'moderator',
  Administrator = 'administrator',
}

export enum UserCommentModerationMode {
  ModerationRequired = 'moderation_required',
  AutomaticApproval = 'automatic_approval',
}


export type FilterWords = {
  id: string
  forbiddenWord: string
}


export enum CommentStatus {
  Approved = 'approved',
  Blocked = 'blocked',
  Deleted = 'deleted',
  None = 'none',
}

export type Comment = CommentTemplateData

type Props = {
  readonly comment: Readonly<Comment>
  readonly handleCommentAction: (id: number, action: SchnackAction, userVote: string | undefined, authorId: number) => void
} & BaseProps

export const cssImage = (index: number) => {
  switch (index) {
    case 1:
      return styles.MediaCard1
    case 2:
      return styles.MediaCard2
    case 3:
      return styles.MediaCard3
    case 4:
      return styles.MediaCard4
    default:
      return styles.MediaCard1
  }
}

const CommentsItem: React.VFC<Props> = ({
  onSelected,
  selected,
  options,
  comment,
  handleCommentAction
}) => {
  const { goTo } = useNavigation()

  return (
    <ListItem
      onSelected={onSelected}
      selected={selected}
      options={options}
    >
      <Col additionalClassNames={[styles.ColInfo, comment.muted ? styles.ColInfoMuted : '']}>
        <div className={styles.RowTitle}>
          <div className={styles.LinkTitle}>
            {comment.muted && <span>HIDDEN • </span>}<a href={comment.slug} target='_blank'>{comment.title ? comment.title : comment.slug}</a>
          </div>
          <div>
            <Dropdown
              trigger={<ButtonIconNoBackGround additionalClassNames={[styles.IconOptions]} />}
              position={'left'}
              topDown={-20}
              leftDown={10}
            >
              {options}
            </Dropdown>
          </div>

        </div>
        <div className={styles.RowContent}>
          <div className={styles.MainInfo}>
            <p className={styles.UserInfo}>
              <span>{comment.displayName}</span> - {moment(comment.createdAt).fromNow()}
              {comment.edited && <span>{' '}&bull; edited</span>}
            </p>
            <p dangerouslySetInnerHTML={{ __html: comment.comment }} />
            <div className={styles.Media}>
              {Boolean(comment.images.length) && comment.images.map((url, index) => (
                <div className={`${styles.MediaCard} ${cssImage(comment.images.length)}`} key={index}>
                  <img src={url} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.RowAction}>
          {comment.likes} <ButtonIconNoBackGround onClick={()=> handleCommentAction(comment.id, 'up', comment.userVote, comment.userId)} additionalClassNames={[comment.userVote === 'up' ? styles.IconLiked : styles.IconLike]}/>
          {comment.dislikes} <ButtonIconNoBackGround onClick={()=> handleCommentAction(comment.id, 'down', comment.userVote, comment.userId)} additionalClassNames={[comment.userVote === 'down' ? styles.IconDisliked : styles.IconDislike]}/>
          <a href={`${comment.slug}#comment-${comment.id}`} target='_blank' className={styles.ViewComment}>View in discussion</a>
        </div>
      </Col>
    </ListItem>
  )
}

export default CommentsItem
