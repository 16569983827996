import React from 'react'
import styles from '../../../CSS/main.css'
import { useNavigation } from '../../utils/navigation'
import { Routes } from '../../routing'

const NotFound404: React.VFC = () => {
    document.title = 'Not Found 404'
    const { goToNotParams } = useNavigation()
    return (
        <div className={styles.NotFoundContainer}>
            <span>Not Found 404</span>
            <div className={styles.LogoComment} />
            <button onClick={() => goToNotParams(Routes.Notifications)}>Go To Dashboard</button>
        </div>
    )
}

export default NotFound404