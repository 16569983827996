import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { History } from 'history'
import { ExtractRouteParams, Routes, urlWithParams } from '../routing'

type NavigationHooks = {
  readonly history: History
  readonly goBack: () => void
  readonly goTo: (url: Routes, params: ExtractRouteParams) => void
  readonly goToNewTab: (url: string) => void
  readonly goToNotParams: (url: string) => void
  readonly goToHref: (url: string) => void
}

export const useNavigation = (): NavigationHooks => {
  const history = useHistory()

  const goBack = useCallback(() => {
    history.goBack()
  }, [])

  const goTo = useCallback((url: Routes, params: ExtractRouteParams) => {
    history.push(urlWithParams(url, params))
  }, [])

  const goToNotParams = useCallback((url: string) => {
    history.push(url)
  }, [])

  const goToHref = (url: string) => {
    document.location.href = url
  }

  const goToNewTab = (url: string) => {
    window.open(url, '_blank');
  }

  return {
    history,
    goBack,
    goTo,
    goToNewTab,
    goToNotParams,
    goToHref
  }
}
