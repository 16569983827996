import { useAsync, useAsyncCallback } from "react-async-hook"
import { GetPaginatedResponse, UserBlockFilter, UserBlockTemplateData } from "../../../types"
import { getUserBlock, unblockUsers } from "../../../api/requests"
import { useCallback, useMemo, useState } from "react"

export const useUserBlockState = () => {
    const [listState, setListState] = useState<UserBlockFilter>({
        page: 0
    })
    const [selectedItems, setSelectedItems] = useState<ReadonlyArray<UserBlockTemplateData>>([])
    const [isLoading, setIsLoading] = useState(false)

    const fetcher = useAsync<GetPaginatedResponse<UserBlockTemplateData>>(() => {
        const { page } = listState;
        setIsLoading(false)
        return getUserBlock({ page: page })
    }, [listState])

    const items = useMemo(() => {
        return fetcher.result?.rows || []
    }, [fetcher.result])

    const pageCount = useMemo(() => {
        return Math.ceil((fetcher.result?.count || 0) / 20)
    }, [fetcher.result?.count])

    const setListParams = useCallback((update: UserBlockFilter | ((prev: UserBlockFilter) => UserBlockFilter)) => {
        if (!fetcher.loading) {
            setListState(update)
        }
    }, [fetcher.loading])

    const refetch = useCallback(() => {
        void fetcher.execute()
    }, [fetcher.loading])

    const allSelected = items.length ? (selectedItems.length === items.length) : false
    const toggleAll = useCallback((): void => {
        setSelectedItems(allSelected ? [] : items)
    }, [allSelected, items])

    const select = useCallback(
        (item: UserBlockTemplateData): void => {
            setSelectedItems(
                selectedItems.includes(item)
                    ? selectedItems.filter((i) => i !== item)
                    : [...selectedItems, item]
            )
        },
        [selectedItems]
    )

    const performChangeAction = useAsyncCallback(unblockUsers)

    const onUnblocks = useCallback(async (blockIds: ReadonlyArray<number>) => {
        await performChangeAction.execute({ blockIds })
        refetch()
    }, [refetch])

    const handleUnblocks = useCallback(async (blockIds: ReadonlyArray<number>) => {
        setIsLoading(true)
        await onUnblocks(blockIds)
    }, [onUnblocks])

    return {
        items,
        isLoading: isLoading || fetcher.loading,
        refetch,
        toggleAll,
        select,
        pageCount,
        selectedItems,
        setListParams,
        allSelected,
        handleUnblocks
    }
}