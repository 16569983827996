import React, { useCallback, useContext, useMemo } from 'react'
import { DimensionsContext } from '../../utils/dimensions-context'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import { ButtonIcon } from '../Button/Button'
import { PortalType, SlotPortal } from '../Portal'

const DesktopMenu: React.FC = ({ children }) => {
  const {
    state: { headerHeight, minimized }
  } = useContext(DimensionsContext)

  const menuClasses = classNames({
    [styles.Menu]: true,
    [styles.SideMenuB]: true,
    [styles.MinimizedMenu]: minimized,
  })

  const dynStyle = useMemo<React.CSSProperties>(
    () => ({
      paddingTop: headerHeight,
    }),
    [headerHeight]
  )

  return (
    <div className={menuClasses} style={dynStyle}>
      <div className={styles.Content}>{children}</div>
    </div>
  )
}

export default DesktopMenu
