import React from "react"
import Spinner from "../../../routes/Toast/Spinner"
import styles from '../../../../CSS/main.css'
import { useUserBlockState } from "./UserBlock.hooks"
import { format } from 'date-fns'

const BlockUserTab: React.FC = () => {
    const {
        items,
        isLoading,
        refetch,
        toggleAll,
        select,
        pageCount,
        setListParams,
        selectedItems,
        allSelected,
        handleUnblocks
    } = useUserBlockState()

    return (
        <>
            {items
                ? Boolean(items.length)
                    ?
                    <div>
                        <div className={styles.TableBlockUsers}>
                            <div>
                                <input onChange={toggleAll} checked={allSelected} type="checkbox" />
                            </div>
                            <div>
                                Username
                            </div>
                            <div>
                                Date Blocked
                            </div>
                            <div>
                                <button onClick={()=> handleUnblocks(selectedItems.map(user => user.id))} style={{ display: `${selectedItems.length ? 'block' : 'none'}` }}>Unblock</button>
                            </div>
                        </div>
                        <div id='table-user-block' className={styles.ListItemBlockUsers}>
                            {items.map((user) => (
                                <div key={user.id} className={styles.TableBlockUsersItems}>
                                    <div>
                                        <input onChange={() =>select(user)} checked={selectedItems.includes(user)} type="checkbox" />
                                    </div>
                                    <div>
                                        {user.userName}
                                    </div>
                                    <div>
                                        {format(new Date(user.blockTime), 'M/d/yyyy')}
                                    </div>
                                    <div>
                                        <a onClick={()=> handleUnblocks([user.id])}>Unblock</a>
                                    </div>
                                </div>
                            ))}
                            {isLoading && <Spinner />}
                        </div>
                    </div>
                    :
                    <div className={styles.BlockUserNodata}>
                        You have no blocked users
                    </div>
                : <div><Spinner /></div>
            }
        </>
    )
}

export default BlockUserTab