import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import { DimensionsContext } from '../../utils/dimensions-context'

type Props = {
  readonly isInfoPage?: boolean
}

const Page: React.FC<Props> = ({ isInfoPage, children }) => {
  const { toolbarHeight, headerHeight } = useContext(DimensionsContext).state
  const classes = classNames({
    [styles.InfoPage]: isInfoPage,
    [styles.Page]: true,
  })

  const dynStyle = useMemo<React.CSSProperties>(
    () => ({
      marginTop: `${Number(toolbarHeight) + Number(headerHeight)}px`,
    }),
    [toolbarHeight, headerHeight]
  )

  return (
    <div className={classes} style={dynStyle}>
      {children}
    </div>
  )
}

export default Page
