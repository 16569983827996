import { useEffect } from "react";

const usePasteAsPlainText = (): void => {
  const handleGlobalPaste = (event: ClipboardEvent): void => {
    const clipboardData = event.clipboardData?.getData("text/plain");

    if (document.activeElement instanceof HTMLElement) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(clipboardData || ''));
      }
    }

    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("paste", handleGlobalPaste);
    return () => {
      document.removeEventListener("paste", handleGlobalPaste);
    };
  }, []);
};

export default usePasteAsPlainText;
