import React, { useCallback } from 'react'
import { Col } from '../Col'
import styles from '../../../../CSS/main.css'
import { useNavigation } from '../../../utils/navigation'
import ListItem, { BaseProps } from './ListItem'
import { ButtonIconNoBackGround } from '../../Button/Button'
import Dropdown from '../../Dropdown'
import moment from 'moment'
import { NotificationTemplateData, SchnackAction } from '../../../types'
import { CommentTemplateData } from '../../../types'

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
  Paused = 'paused',
}

export enum AccountType {
  User = 'user',
  Moderator = 'moderator',
  Administrator = 'administrator',
}

export enum UserCommentModerationMode {
  ModerationRequired = 'moderation_required',
  AutomaticApproval = 'automatic_approval',
}


export type FilterWords = {
  id: string
  forbiddenWord: string
}


export enum CommentStatus {
  Approved = 'approved',
  Blocked = 'blocked',
  Deleted = 'deleted',
  None = 'none',
}

export type Notification = NotificationTemplateData

type Props = {
  readonly notification: Readonly<Notification>
  readonly handleOpenModal: (comment: CommentTemplateData) => void
  readonly handleCommentAction: (id: number, action: SchnackAction, userVote: string | undefined, authorId: number) => void
} & BaseProps

const cssImage = (index: number) => {
  switch (index) {
    case 1:
      return styles.MediaCard1
    case 2:
      return styles.MediaCard2
    case 3:
      return styles.MediaCard3
    case 4:
      return styles.MediaCard4
    default:
      return styles.MediaCard1
  }
}

const NotificationItem: React.VFC<Props> = ({
  onSelected,
  selected,
  options,
  notification,
  handleOpenModal,
  handleCommentAction
}) => {
  const { comment, targetComment, vote } = notification

  const renderTotalTitle = (reactTotal: number, title: string, displayName: string, link: string, type: string) => {
    switch (reactTotal) {
      case 1: return <><span>{displayName}</span> {type} your comment on <a href={link} target='_blank'>{title}</a></>;
      case 2: return <><span>{displayName}</span> and 1 other {type} your comment on <a href={link} target='_blank'>{title}</a></>;
      default: return <><span>{displayName}</span> and {reactTotal - 1} others {type} your comment on <a href={link} target='_blank'>{title}</a></>;
    }
  }

  const renderVoteTitle = (type: string, displayName: string) => {
    const totalReact = type === 'up' ? comment.likes : comment.dislikes
    return renderTotalTitle(totalReact, comment.title || comment.slug, displayName, comment.slug, type === 'up' ? 'upvoted' : 'downvoted')
  }

  return (
    <ListItem
      onSelected={onSelected}
      selected={selected}
      options={options}
    >
      <Col additionalClassNames={[styles.ColInfo, notification.isRead ? styles.ColInfoMuted : '']}>
        <div className={styles.RowTitle}>
          <div className={styles.LinkTitle}>
            {notification.isRead && <span>READ • </span>}
            {vote
              ? renderVoteTitle(vote.type, vote.displayName)
              : <>
                <span>{targetComment.displayName}</span> replied to your comment on <a href={comment.slug} target='_blank'>{comment.title || comment.slug}</a>
              </>
            }
            • {' '}{moment(notification.createdAt).fromNow()}
          </div>
          <div>
            <Dropdown
              trigger={<ButtonIconNoBackGround additionalClassNames={[styles.IconOptions]} />}
              position={'left'}
              topDown={-20}
              leftDown={10}
            >
              {options}
            </Dropdown>
          </div>

        </div>
        <div className={styles.RowContent}>
          <div className={styles.MainInfo}>
            <p className={styles.UserInfo}>
              <span>{comment.displayName}</span> • {moment(comment.createdAt).fromNow()}
            </p>
            <p dangerouslySetInnerHTML={{ __html: comment.comment }} />
            <div className={styles.Media}>
              {Boolean(comment.images.length) && comment.images.map((url, index) => (
                <div className={`${styles.MediaCard} ${cssImage(comment.images.length)}`} key={index}>
                  <img src={url} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
        {vote && <div className={styles.RowAction}>
          {comment.likes} <ButtonIconNoBackGround onClick={() => handleCommentAction(comment.id, 'up', comment.userVote, comment.userId)} additionalClassNames={[comment.userVote === 'up' ? styles.IconLiked : styles.IconLike]} />
          {comment.dislikes} <ButtonIconNoBackGround onClick={() => handleCommentAction(comment.id, 'down', comment.userVote, comment.userId)} additionalClassNames={[comment.userVote === 'down' ? styles.IconDisliked : styles.IconDislike]} />
          <a href={`${comment.slug}#comment-${comment.id}`} target='_blank' className={styles.ViewComment}>View in discussion</a>
        </div>}
        {!vote && <div className={styles.InfoReply}>
          <div className={styles.ReplyImage}>
            <img src={targetComment.avatar} alt="" />
          </div>
          {!targetComment.muted ? <div className={styles.MainInfo}>
            <p className={styles.UserInfo}>
              <span>{targetComment.displayName} </span> • {' '}{moment(targetComment.createdAt).fromNow()}
            </p>
            <p dangerouslySetInnerHTML={{ __html: targetComment.comment }} />
            <div className={styles.Media}>
              {Boolean(targetComment.images.length) && targetComment.images.map((url, index) => (
                <div className={`${styles.MediaCard} ${cssImage(targetComment.images.length)}`} key={index}>
                  <img src={url} alt="" />
                </div>
              ))}
            </div>
            <div className={styles.RowAction}>
              {targetComment.likes} <ButtonIconNoBackGround onClick={() => handleCommentAction(targetComment.id, 'up', targetComment.userVote, targetComment.userId)} additionalClassNames={[targetComment.userVote === 'up' ? styles.IconLiked : styles.IconLike]} />
              {targetComment.dislikes} <ButtonIconNoBackGround onClick={() => handleCommentAction(targetComment.id, 'down', targetComment.userVote, targetComment.userId)} additionalClassNames={[targetComment.userVote === 'down' ? styles.IconDisliked : styles.IconDislike]} />
              <span onClick={() => handleOpenModal(targetComment)}>Reply</span>
              <a href={`${targetComment.slug}#comment-${targetComment.id}`} target='_blank' className={styles.ViewComment}>View in discussion</a>
            </div>
          </div> : <div className={styles.MainInfo}>
            <p className={styles.UserInfo}>
              <span>{targetComment.displayName} </span> • {' '}{moment(targetComment.createdAt).fromNow()}
            </p>
            <p>(This comment has been muted by the user)</p>
          </div>}
        </div>}
      </Col>
    </ListItem>
  )
}

export default NotificationItem
