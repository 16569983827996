import React, { useState } from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import useMediaQuery from '../../utils/useMediaQuery'
import MobileMenu from './Mobile'
import DesktopMenu from './Desktop'
import { Routes, TypedLink } from '../../routing'

type NavigationState = {
  unreadCount?: number,
  setOpenSettingModal: () => void
}

const Navigation: React.VFC<NavigationState> = ({ unreadCount, setOpenSettingModal }) => {

  const [tab, setTab] = useState(document.location.pathname)
  const items = (
    <>
      <div className={styles.LinkGroup}>
        <TypedLink
          className={classNames([styles.Link, tab.toLocaleLowerCase() === Routes.Notifications ? styles.TabActivePath : ''])}
          title='Manage Notifications'
          to={Routes.Notifications}>
          <span
            className={classNames([styles.LinkIcon, Boolean(unreadCount) ? styles.IconNotificationsActive : styles.IconNotifications])}
          />
          <span  className={styles.LinkLabel}>Notifications</span>
        </TypedLink>
        <TypedLink
          className={classNames([styles.Link, tab.toLocaleLowerCase() === Routes.Comments ? styles.TabActivePath : ''])}
          title='Manage Comments'
          to={Routes.Comments}>
          <span className={classNames([styles.LinkIcon, styles.IconComments])} />
          <span className={styles.LinkLabel}>My Comments</span>
        </TypedLink>
        <TypedLink 
          className={classNames([styles.Link, tab.toLocaleLowerCase() === Routes.Bookmarks ? styles.TabActivePath : ''])} 
          title='Manage Bookmarks' 
          to={Routes.Bookmarks}
        >
          <span className={classNames([styles.LinkIcon, styles.IconBookmarks])} />
          <span className={styles.LinkLabel}>Bookmarks</span>
        </TypedLink>
      </div>
    </>
  )

  return (
    <>
      <MobileMenu setOpenSettingModal={setOpenSettingModal}>{items}</MobileMenu>
      <DesktopMenu>{items}</DesktopMenu>
    </>
  )
}

export default Navigation
