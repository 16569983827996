import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import { ButtonIcon } from '../Button/Button'
import Portal, { PortalType, SlotPortal } from '../Portal'
import { Routes, TypedLink } from '../../routing'
import { getMe, signout } from '../../api/requests'
import { useAsync } from 'react-async-hook'
import { User } from '../../types'

type MobileMenu = {
  children: ReactNode,
  setOpenSettingModal: () => void
}

const useHeaderState = () => {
  const fetchUser = useAsync<User>(getMe, [])

  return {
    user: fetchUser.result,
  }
}

const MobileMenu: React.FC<MobileMenu> = ({ children, setOpenSettingModal }) => {
  const [open, setOpen] = useState(false)
  const { user } = useHeaderState()
  const adminUrl : string = useMemo(() => {
    const url = new URL(window.location.href)
    return `${url.protocol}//admin-${url.host}`
  }, [])

  const dynStyle = useMemo<React.CSSProperties | undefined>(
    () => ({
      left: open ? 0 : undefined,
    }),
    [open]
  )

  const toggleMenu = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const onSignOut = useCallback(() => {
    signout().then((data) => window.location.href = data.link)
  }, [])

  return (
    <div
      className={classNames([styles.Menu, styles.MenuLeft, styles.SideMenuA])}
      style={dynStyle}>
      <div className={styles.Content}>
        <SlotPortal name={PortalType.Burger}>
          <ButtonIcon
            onClick={toggleMenu}
            additionalClassNames={[styles.IconMenu, styles.ShowMenu]}
            title={'Toggle Menu'}
          />
        </SlotPortal>
        <ButtonIcon
          title='Close'
          onClick={toggleMenu}
          additionalClassNames={[styles.IconClose, styles.MenuClose]}
        />
        {children}
        <div className={classNames([styles.LinkGroup, styles.LinkGroupMargin])}>
          <a
            className={styles.Link}
            title='Edit Profile'
            href='https://www.brighteon.com/dashboard/account'
            target='_blank'
          >
            <span
              className={classNames([styles.LinkIcon, styles.IconProfileTab])}
            />
            <span className={styles.LinkLabel}>Edit Profile</span>
            <div className={styles.IconExternalLink} />
          </a>
          <a
            className={styles.Link}
            title='Setting'
            onClick={setOpenSettingModal}
          >
            <span className={classNames([styles.LinkIcon, styles.IconSettingTab])} />
            <span className={styles.LinkLabel}>Setting</span>
          </a>
          <div className={styles.Link} title='Sign Out' onClick={onSignOut}>
            <span className={classNames([styles.LinkIcon, styles.IconLogoutTab])} />
            <span className={styles.LinkLabel}>Sign Out</span>
          </div>
        </div>
        <div className={classNames([styles.LinkGroup, styles.LinkGroupMargin])}>
          <a
            className={styles.Link}
            title='Support Center'
            href='https://support.brighteon.com'
            target='_blank'
          >
            <span className={styles.LinkNoIcon}>Support Center</span>
            <div className={styles.IconExternalLink}/>
          </a>
          <a
            className={styles.Link}
            title='Privacy Policy'
            href='https://legal.brighteon.com/PrivacyPolicy.html'  
            target='_blank'
          >
            <span className={styles.LinkNoIcon}>Privacy Policy</span>
            <div className={styles.IconExternalLink}/>
          </a>
          <a className={styles.Link} title='Terms & Conditions' href='https://legal.brighteon.com/TermsofService.html' target='_blank'>
            <span className={styles.LinkNoIcon}>Terms & Conditions</span>
            <div className={styles.IconExternalLink}/>
          </a>
          {user?.admin && (
            <a className={styles.Link} title='Admin Dashboard' href={adminUrl} target='_blank'>
              <span className={styles.LinkNoIcon}>Admin Dashboard</span>
              <div className={styles.IconExternalLink}/>
            </a>
          )}
        </div>
        <div className={styles.MenuCopyrightText}>
          <span>© 2023 All Rights Reserved. Privacy | Terms All content posted on this site is commentary or opinion and is protected under Free Speech. Brighteon is not responsible for content uploaded by contributing content providers.</span>
        </div>
        {open && (
          <Portal>
            <div
              onClick={toggleMenu}
              style={{ display: 'block' }}
              className={classNames([styles.ButtonCover, styles.CoverDark])}
            />
          </Portal>
        )}
      </div>
    </div>
  )
}

export default MobileMenu
