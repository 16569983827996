import React from 'react'
import styles from '../../../CSS/main.css'
import Layout from '../../Components/Layout'
import ListPage from '../../Components/List/ListPage'
import { ColInfo, ColOptions, ColSelect} from '../../Components/List/Col'
import Dropdown from '../../Components/Dropdown'
import { ButtonIcon, ButtonIconSquare } from '../../Components/Button/Button'
import DropdownGroup from '../../Components/Dropdown/DropdownGroup'
import DropdownItem from '../../Components/Dropdown/DropdownItem'
import { useListState } from '../../Components/List/hooks'
import { NotificationTemplateData } from '../../types'
import { NOTIFICATIONS_BOOKMARK_STATE, INIT_STATE } from './filters'
import { getBookmarks, getSearchNotifications } from '../../api/requests'
import NotificationItem from '../../Components/List/Item/NotificationItem'
import Modal from '../../Components/Modal'
import ReplyModal from '../../Components/ReplyModal'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const Search: React.VFC = () => {
    document.title = 'Search'

    const { search } = useLocation()
    const queryParams = queryString.parse(search)

    const {
        params,
        select,
        toggleAll,
        selectedItems,
        items,
        pageCount,
        loading,
        allSelected,
        onPageChange,
        refetch,
        setListParams,
        commentModal,
        open,
        isRefreshCount,
        handlePostReply,
        handleMute,
        onChangeOpen,
        handleBookmark,
        handleDelete,
        handleOpenModal,
        handleCommentAction,
        handleRead
    } = useListState<NotificationTemplateData, NOTIFICATIONS_BOOKMARK_STATE>(
        { ...INIT_STATE, searchTerm: queryParams.query ? queryParams.query.toString() : '' }, 
        getSearchNotifications
    )
    const { page } = params

    return (
        <Layout isRefreshCount={isRefreshCount}>
            {commentModal && <Modal open={open} onClose={onChangeOpen} children={<ReplyModal onReply={handlePostReply} comment={commentModal} />} />}
            <ListPage
                loading={loading}
                items={items}
                itemRenderer={(i) => (
                    <NotificationItem
                        key={i.id + i.id}
                        onSelected={() => select(i)}
                        selected={selectedItems.includes(i)}
                        notification={i}
                        handleOpenModal={handleOpenModal}
                        handleCommentAction={handleCommentAction}
                        options={
                            <>
                                <DropdownGroup>
                                    {Boolean(i.targetComment) && <DropdownItem onClick={() => handleOpenModal(i.targetComment)}>Reply</DropdownItem>}
                                    <DropdownItem onClick={() => handleRead({ ids: [i.id], value: true, authorId: i.comment.userId })}>Mark as Read</DropdownItem>
                                    <DropdownItem onClick={() => handleRead({ ids: [i.id], value: false, authorId: i.comment.userId })}>Mark as Unread</DropdownItem>
                                    <DropdownItem onClick={() => handleBookmark({ ids: [i.id], value: false, authorId: i.comment.userId })}>Remove Bookmark</DropdownItem>
                                    <DropdownItem onClick={() => handleMute({ ids: [i.id], value: true, authorId: i.comment.userId })}>Hide</DropdownItem>
                                    <DropdownItem onClick={() => handleMute({ ids: [i.id], value: false, authorId: i.comment.userId })}>Show</DropdownItem>
                                    <DropdownItem onClick={() => handleDelete({ ids: [i.id], authorId: i.comment.userId })} highlighted>Delete</DropdownItem>
                                </DropdownGroup>
                            </>
                        }
                    />
                )}
                headerRenderer={() => (
                    <>
                        <ColSelect
                            onChange={toggleAll}
                            showActions={!!selectedItems.length}
                            toggle={allSelected}>
                            <Dropdown
                                trigger={
                                    <ButtonIcon
                                        title='Select Options'
                                        additionalClassNames={[styles.SelectOptions]}
                                    />
                                }
                                position={'right'}
                                topDown={-20}
                            >
                                <DropdownGroup>
                                    <DropdownItem onClick={() => handleRead({ ids: selectedItems.map(i => i.id), value: true, authorId: selectedItems[0]?.comment.userId })}>Mark as Read</DropdownItem>
                                    <DropdownItem onClick={() => handleRead({ ids: selectedItems.map(i => i.id), value: false, authorId: selectedItems[0]?.comment.userId })}>Mark as Unread</DropdownItem>
                                    <DropdownItem onClick={() => handleBookmark({ ids: selectedItems.map(i => i.id), value: false, authorId: selectedItems[0]?.comment.userId })}>Remove Bookmark</DropdownItem>
                                    <DropdownItem onClick={() => handleDelete({ ids: selectedItems.map(i => i.id), authorId: selectedItems[0]?.comment.userId })} highlighted>Delete</DropdownItem>
                                </DropdownGroup>
                            </Dropdown>
                        </ColSelect>
                        <ColInfo>
                            <span className={styles.TabTitle}>Search Results</span>
                        </ColInfo>
                        <ColOptions>
                            <Dropdown
                                trigger={<ButtonIconSquare additionalClassNames={[styles.IconFilter]} />}
                                position={'left'}
                                topDown={-20}
                                leftDown={-30}
                            >
                                <DropdownGroup>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'new'})}}>Newest</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'old'})}}>Oldest</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'up'})}}>Upvotes</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'down'})}}>Downvotes</DropdownItem>
                                </DropdownGroup>
                            </Dropdown>
                        </ColOptions>
                    </>
                )}
                title={'Manage Search'}
                onBack={null}
                pageCount={pageCount}
                page={page}
                onPageChange={onPageChange}
            />
        </Layout>
    )
}

export default Search