import React, { useCallback, useEffect, useState } from 'react'
import { switchTheme, Theme } from './switchTheme'

type Context = {
  readonly theme: Theme
  readonly update: (theme: Theme) => void
}

export const ThemeContext = React.createContext<Context>({
  theme: Theme.Light,
  update: (theme: Theme) => null,
})

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState(Theme.Light)

  useEffect(() => {
    switchTheme(theme)
  }, [theme])

  const update = useCallback(
    (theme: Theme) => {
      setTheme(theme)
    },
    [theme]
  )

  return (
    <ThemeContext.Provider value={{ theme, update }}>
      {children}
    </ThemeContext.Provider>
  )
}
