import React, { ReactNode } from "react";
import * as css from "../../../CSS/main.css";

const CustomUndo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
  >
    <path d="M17.856 24c2.665-4.83 3.115-12.195-7.356-11.95V18l-9-9 9-9v5.82C23.038 5.495 24.435 16.89 17.856 24z" />
  </svg>
);

const CustomRedo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
  >
    <path d="M13.5 5.82V0l9 9-9 9v-5.95C3.03 11.806 3.478 19.17 6.144 24-.436 16.89.962 5.494 13.5 5.82z" />
  </svg>
);

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const QuillToolbar = ({
  id,
  imageButton,
  children,
}: {
  id: string;
  imageButton: ReactNode;
  children: ReactNode;
}) => (
  <div id={`toolbar-${id}`} className={css.ToolbarQuill}>
    <span className={`${css.ButtonIconToolBar} ql-formats`}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className={`${css.ButtonIconToolBar} ql-formats`}>
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className={`${css.ButtonIconToolBar} ql-formats`}>
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
    <span className={`${css.ButtonIconToolBar} ql-formats`}>
      <button className="ql-link" />
      {imageButton}
    </span>
    {children}
  </div>
);

export default QuillToolbar;
