import React, { useCallback } from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import { Col } from './Col'
import {Tab} from "../Filters/types";

type Props = {
  readonly tabs: ReadonlyArray<Tab>
  readonly activeTab: string
  readonly onChangeTab: (tab: string) => void
}

const ListTabs: React.VFC<Props> = ({ tabs, activeTab, onChangeTab }) => {
  return (
      <div className={styles.Tabs}>
        {tabs.map((tab) => {
          const isSelected = activeTab === tab.value
          const classes = classNames({
            [styles.Tab]: true,
            [styles.TabActive]: isSelected,
          })

          const selectTab = useCallback(() => {
            onChangeTab(tab.value)
          }, [tab, onChangeTab])

          return (
            <div key={tab.value} className={classes} onClick={selectTab}>
              {tab.label}
            </div>
          )
        })}
      </div>
  )
}

export default ListTabs
