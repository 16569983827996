import React from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import {Col} from '../List/Col'

const WelcomeMessage: React.VFC = () => {
  return (
    <div className={classNames([styles.Table, styles.TopLinks])}>
      <Col>
        Brighteon Comments are now live on NewsTarget.com. More sites coming soon.
      </Col>
    </div>
  )
}

export default WelcomeMessage
