import { useAsync, useAsyncCallback } from "react-async-hook"
import { SettingTemplateData, UpdateNotificationSettings } from "../../../types"
import { getSetting, muteAllComments, removeAllComments, updateNotificationSettings } from "../../../api/requests"
import { useCallback, useMemo, useState } from "react"

export const useSettingState = () => {
    const [checkDelete, setCheckDelete] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fetcher = useAsync<SettingTemplateData>(() => {
        setIsLoading(false)
        return getSetting()
    }, [])

    const items = useMemo(() => {
        return fetcher.result
    }, [fetcher.result])
    

    const refetch = useCallback(() => {
        void fetcher.execute()
    }, [fetcher.loading])

    const performMuteAction = useAsyncCallback(muteAllComments)

    const onMute = useCallback(async (value: boolean) => {
        await performMuteAction.execute(value)
        refetch()
    }, [refetch])

    const handleMute = useCallback(async (value: boolean) => {
        setIsLoading(true)
        await onMute(value)
    }, [onMute]) 

    const performRemoveAction = useAsyncCallback(removeAllComments)

    const onRemove = useCallback(async () => {
        await performRemoveAction.execute()
        refetch()
    }, [refetch])

    const handleRemove = useCallback(async () => {
        setIsLoading(true)
        await onRemove()
        setCheckDelete(false)
    }, [onRemove])

    const performChangeAction = useAsyncCallback(updateNotificationSettings)

    const onChange = useCallback(async ({ likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail }: UpdateNotificationSettings) => {
        await performChangeAction.execute({ likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail })
        refetch()
    }, [refetch])

    const handleChange = useCallback(async ({ likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail }: UpdateNotificationSettings) => {
        setIsLoading(true)
        await onChange({ likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail })
    }, [onChange])

    return {
        items,
        isLoading: isLoading || fetcher.loading,
        refetch,
        handleRemove,
        handleMute,
        handleChange,
        checkDelete,
        setCheckDelete
    }
}
