import React, { useEffect } from 'react'
import styles from '../../../CSS/main.css'
import { useNavigation } from '../../utils/navigation'
import { Routes } from '../../routing'
import BrighteonLogo from '../../../Images/Brighteon-Logo.svg'
import { getUserLogin } from '../../utils/signin'
const SignIn: React.VFC = () => {
    document.title = 'Sign In'
    const { goToNotParams } = useNavigation()
    const origin = `${document.location.origin}${Routes.Notifications}`
    const linkSignIn = `${process.env.BE_HOST || 'https://schnack-dev.local:3001/api'}/auth?origin=${encodeURIComponent(origin)}`

    useEffect(() => {
        getUserLogin().then((auth) => {
          if (auth) {
            goToNotParams(Routes.Notifications)
          }
        })
      }, [])

    return (
        <div className={styles.SignInContainer}>
            <div className={styles.SignInBox}>
                <div className={styles.SignInCommentsLogo}></div>
                <form className={styles.SignInCommentsForm}>
                    <div className={styles.Title}>Sign In With Brighteon Account</div>
                    <img className={styles.BrighteonLogo} src={BrighteonLogo} alt='Brighteon Logo'/>
                    <a href={linkSignIn}>Sign In</a>
                </form>
                <div className={styles.SignInCopyright}>
                    <span>If you already have an account at Brighteon.com, you can use that account here. If not, you can <a href='https://www.brighteon.com/dashboard/request-invitation' target='_blank'>create a free account today</a>.</span>
                </div>
                <div className={styles.SignInRectangle}/>
                <div className={styles.SignInIllustration}/>
                <div className={styles.LearnMoreBox}>
                    <div className={styles.LearnMoreText}>
                        <span>Express yourself freely without any censorship</span>
                        <p>We are dedicated to promoting free speech and liberty and we value the importance of open communication.</p>
                    </div>
                    <button onClick={() => { goToNotParams(Routes.About) }}>Learn More</button>
                </div>
                <span onClick={() => { goToNotParams(Routes.About) }} className={styles.LearMoreLink}>Learn More</span>
            </div>
        </div>
    )
}

export default SignIn