import React, {useCallback} from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import noPropagation from '../../utils/noPropagation'
import {useAsyncCallback} from "react-async-hook";

type Props = {
  readonly title?: string
  readonly onClick?: (e: React.MouseEvent<any>) => void | Promise<void>
  readonly additionalClassNames?: ReadonlyArray<string>
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  additionalClassNames = [],
}) => {
  const asyncClick = useAsyncCallback(noPropagation(onClick))
  return (
    <button
      className={classNames([styles.Button, ...additionalClassNames])}
      disabled={asyncClick.loading}
      style={{opacity: asyncClick.loading ? 0.3 : 1}}
      onClick={asyncClick.execute}>
      {children}
    </button>
  )
}

export const ButtonIcon: React.FC<Props> = ({
  title,
  onClick,
  additionalClassNames = [],
}) => {
  const handleClick = useCallback(noPropagation(onClick), [onClick])
  return (
    <button
      title={title}
      className={classNames([styles.ButtonIcon, ...additionalClassNames])}
      onClick={handleClick}
    />
  )
}

export const ButtonIconSquare: React.FC<Props> = ({
  title,
  onClick,
  additionalClassNames = [],
}) => {
  const handleClick = useCallback(noPropagation(onClick), [onClick])
  return (
    <button
      title={title}
      className={classNames([styles.ButtonIconSquare, ...additionalClassNames])}
      onClick={handleClick}
    />
  )
}

export const ButtonIconNoBackGround: React.FC<Props> = ({
  title,
  onClick,
  additionalClassNames = [],
}) => {
  const handleClick = useCallback(noPropagation(onClick), [onClick])
  return (
    <button
      title={title}
      className={classNames([styles.ButtonIconNoBackGround, ...additionalClassNames])}
      onClick={handleClick}
    />
  )
}

export const ButtonActive: React.FC<Props> = ({
  additionalClassNames = [],
  ...rest
}) => {
  return (
    <Button
      {...rest}
      additionalClassNames={[...additionalClassNames, styles.ButtonActive]}
    />
  )
}

export const ButtonIconLeft: React.FC<Props> = ({
  additionalClassNames = [],
  ...rest
}) => {
  return (
    <ButtonIcon
      {...rest}
      additionalClassNames={[...additionalClassNames, styles.IconLeft]}
    />
  )
}

export const ButtonIconRight: React.FC<Props> = ({
  additionalClassNames = [],
  ...rest
}) => {
  return (
    <ButtonIcon
      {...rest}
      additionalClassNames={[...additionalClassNames, styles.IconRight]}
    />
  )
}

export default Button
