import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom'
import { Routes } from './routing'
import About from './routes/About'
import SignIn from './routes/SignIn'
import Comments from './routes/Comments'
import Notifications from './routes/Notifications'
import Bookmarks from './routes/Bookmarks'
import Search from './routes/Search'
import NotFound404 from './routes/NotFound404'

export const AppRouter = (): JSX.Element => {

  return (
    <Router>
      <Switch>
        <Route exact path={Routes.Home}>
          <Redirect to={Routes.Notifications} />
        </Route>
        <Route path={Routes.About} exact component={About} />
        <Route path={Routes.SignIn} exact component={SignIn} />
        <Route path={Routes.Comments} exact component={Comments} />
        <Route path={Routes.Notifications} exact component={Notifications} />
        <Route path={Routes.Bookmarks} exact component={Bookmarks} />
        <Route path={Routes.Search} exact component={Search} />
        <Route component={NotFound404} />
      </Switch>
    </Router>
  )
}
