import { option as O } from 'fp-ts'

export const emptyString = ''

export const fromInputEvent = (
  e: React.ChangeEvent<HTMLInputElement>
): string => e.target.value

export const numToString = (x: number): string => x.toString(10)

export const toEmptyString = (): string => emptyString

export const toInt = (value: string): O.Option<number> => {
  const num = parseInt(value, 10)
  return isNaN(num) ? O.none : O.some(num)
}
