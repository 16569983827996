import React from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import ListTable from './ListTable'
import RowHead from './RowHead'

const ListHeader: React.FC = ({ children }) => {
  return (
    <div className={classNames([styles.ListBox, styles.ListHeader])}>
      <ListTable>
        <RowHead>{children}</RowHead>
      </ListTable>
    </div>
  )
}

export default ListHeader
