import React, {useCallback, useMemo, useState} from 'react'
import styles from '../../../CSS/main.css'
import {Col} from '../List/Col'
import { User } from '../../types'
import Dropdown from '../Dropdown'
import DropdownGroup from '../Dropdown/DropdownGroup'
import classNames from 'classnames'
import { signout } from '../../api/requests'
type Props = {
  user: User
  setOpenSettingModal: () => void
}

const UserBlock: React.VFC<Props> = ({ user, setOpenSettingModal }) => {
  const [open, setOpen] = useState(false)
  const toggleMenu = useCallback(() => setOpen(!open), [open, setOpen])
  const adminUrl : string = useMemo(() => {
    const url = new URL(window.location.href)
    return `${url.protocol}//admin-${url.host}`
  }, [])
  const onSignOut = useCallback(() => {
    signout().then((data) => window.location.href = data.link)
  }, [])

  return (
    <Col additionalClassNames={[styles.NavProfile]}>
      <Dropdown
        trigger={
          <div
            className={styles.LinkProfile}
            title='Profile &amp; Settings'
            onClick={toggleMenu}>
            <div className={styles.ProfilePic}>
              <div
                className={styles.Avatar}
                style={{
                  backgroundImage: `url(${user.avatarUrl})`,
                }}
              />
            </div>
            <div className={styles.ProfileName}>
              <span>{user.displayName}</span>
              <span className={styles.ProfileEmail}>{user.email}</span>
            </div>
        </div>
        }
        position={'bottom'}
        topDown={-55}
        leftDown={10}
      >
        <DropdownGroup>
            <a
              className={styles.Link}
              title='Edit Profile'
              href='https://www.brighteon.com/dashboard/account'
              target='_blank'
            >
              <span
                className={classNames([styles.LinkIcon, styles.IconProfileTab])}
              />
              <span className={styles.LinkLabel}>Edit Profile</span>
              <div className={styles.IconExternalLink} />
            </a>
          <a
            className={styles.Link}
            title='Setting'
            onClick={setOpenSettingModal}
          >
            <span className={classNames([styles.LinkIcon, styles.IconSettingTab])} />
            <span className={styles.LinkLabel}>Setting</span>
          </a>
          <div className={styles.Link} title='Sign Out' onClick={onSignOut}>
            <span className={classNames([styles.LinkIcon, styles.IconLogoutTab])} />
            <span className={styles.LinkLabel}>Sign Out</span>
          </div>
        <DropdownGroup>
          <a
            className={styles.Link}
            title='Support Center'
            href='https://support.brighteon.com'
            target='_blank'
          >
            <span className={styles.LinkNoIcon}>Support Center</span>
            <div className={styles.IconExternalLink}/>
          </a>
          <a
            className={styles.Link}
            title='Privacy Policy'
            href='https://legal.brighteon.com/PrivacyPolicy.html'  
            target='_blank'
          >
            <span className={styles.LinkNoIcon}>Privacy Policy</span>
            <div className={styles.IconExternalLink}/>
          </a>
          <a className={styles.Link} title='Terms & Conditions' href='https://legal.brighteon.com/TermsofService.html' target='_blank'>
            <span className={styles.LinkNoIcon}>Terms & Conditions</span>
            <div className={styles.IconExternalLink}/>
          </a>
          {user?.admin && (
            <a className={styles.Link} title='Admin Dashboard' href={adminUrl} target='_blank'>
              <span className={styles.LinkNoIcon}>Admin Dashboard</span>
              <div className={styles.IconExternalLink}/>
            </a>
          )}
          </DropdownGroup>
        </DropdownGroup>
      </Dropdown>
    </Col>
  )
}

export default UserBlock
