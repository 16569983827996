import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.BE_HOST || 'https://schnack-dev.local:3001/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

const handleUnauthorized = (error: { response: AxiosResponse }) => {
  const statusCode = error.response.status
  if (401 === statusCode) {
    // window.location.reload()
  }
}

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    handleUnauthorized(error)
    return Promise.reject(error)
  }
)

const api = {
  get: <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    axiosInstance.get<any, T>(url, config),
  delete: <T>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    axiosInstance.delete<any, T>(url, config),
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> =>
    axiosInstance.post<any, T>(url, data, config),
  put: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> =>
    axiosInstance.put<any, T>(url, data, config),
  patch: <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> => axiosInstance.patch<any, T>(url, data, config),
}

export default api
