/* eslint-disable functional/no-conditional-statement */
import React, {
  useCallback,
  useMemo,
  useState,
  MouseEvent as ReactMouseEvent,
  useEffect,
} from 'react'
import styles from '../../../CSS/main.css'
import useDimensions from 'react-cool-dimensions'
import useMediaQuery from '../../utils/useMediaQuery'
import classNames from 'classnames'
import Popover from '../Popover'

type Props = {
  readonly trigger: JSX.Element
  readonly position: 'left' | 'right' | 'bottom' | 'top'
  readonly leftDown?: number, 
  readonly topDown?: number
}

const calcDropdownCoordinates = (params: {
  readonly anchorX: number
  readonly anchorY: number
  readonly anchorW: number
  readonly anchorH: number
  readonly dropdownW: number
  readonly dropdownH: number
  readonly relativePosition: string
}): React.CSSProperties => {
  const {
    anchorX,
    anchorY,
    anchorW,
    anchorH,
    dropdownW,
    dropdownH,
    relativePosition,
  } = params

  const viewportHeight = window.innerHeight
  const viewportWidth = window.innerWidth

  switch (relativePosition) {
    case 'left': {
      const leftPos = anchorX - dropdownW
      const screenAwareLeftPos = leftPos < 0 ? anchorX + anchorW : leftPos
      const screenAwareTopPos =
        dropdownH + anchorY > viewportHeight
          ? viewportHeight - dropdownH
          : anchorY
      return {
        left: screenAwareLeftPos,
        top: screenAwareTopPos,
      }
    }
    case 'right': {
      const rightPos = anchorX + anchorW
      const screenAwareRightPos =
        rightPos > viewportWidth ? anchorX - dropdownW : rightPos
      const screenAwareTopPos =
        dropdownH + anchorY > viewportHeight
          ? viewportHeight - dropdownH
          : anchorY
      return {
        left: screenAwareRightPos,
        top: screenAwareTopPos,
      }
    }
    case 'bottom': {
      const horizontalPos = anchorX
      const screenOffset =
        horizontalPos + dropdownW > viewportWidth
          ? viewportWidth - (horizontalPos + dropdownW)
          : 0
      const screenAwareHorizontalPos = horizontalPos + screenOffset
      const topPos = anchorY + anchorH
      const screenAwareTopPos =
        topPos + dropdownH > viewportHeight ? anchorY - dropdownH : topPos
      return {
        left: screenAwareHorizontalPos,
        top: screenAwareTopPos,
      }
    }
    case 'top': {
      const horizontalPos = anchorX
      const screenOffset =
        horizontalPos + dropdownW > viewportWidth
          ? viewportWidth - (horizontalPos + dropdownW)
          : 0
      const screenAwareHorizontalPos = horizontalPos + screenOffset
      const topPos = anchorY - dropdownH
      const screenAwareTopPos = topPos < 0 ? anchorY + anchorH : topPos
      return {
        left: screenAwareHorizontalPos,
        top: screenAwareTopPos,
      }
    }
    default:
      return {
        left: 0,
        top: 0,
      }
  }
}

const Dropdown: React.FC<Props> = ({ trigger, children, position, leftDown = 0, topDown = 0 }) => {
  const [open, setOpen] = useState(false)
  const [anchorPosition, setAnchorPosition] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  })
  const { observe, width, height, unobserve } = useDimensions<HTMLDivElement>({
    useBorderBoxSize: true,
  })

  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    return () => {
      unobserve()
    }
  }, [])

  useEffect(() => {
    if (open) setOpen(false)
  }, [isMobile])

  const dropdownStyle = useMemo((): React.CSSProperties => {
    return {
      display: 'block',
      transition: 'opacity 0.4s',
      opacity: open && width ? 1 : 0,
      pointerEvents: open ? 'unset' : 'none',
      ...(isMobile
        ? {}
        : calcDropdownCoordinates({
            anchorW: anchorPosition.width,
            anchorH: anchorPosition.height,
            anchorX: anchorPosition.left,
            anchorY: anchorPosition.top,
            dropdownW: width,
            dropdownH: height,
            relativePosition: position,
          })),
    }
  }, [open, anchorPosition, width, height])

  const coverStyle = useMemo(
    (): React.CSSProperties => ({
      display: open ? 'block' : 'none',
    }),
    [open]
  )

  const toggleDropdown = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      const boundRect = e.currentTarget.getBoundingClientRect()
      if (!open) {
        setAnchorPosition({
          left: boundRect.left - leftDown,
          top: boundRect.top - topDown,
          width: e.currentTarget.offsetWidth,
          height: e.currentTarget.offsetHeight,
        })
      }
      setOpen(!open)
      e.stopPropagation()
    },
    [open]
  )

  const coverClassNames = classNames({
    [styles.ButtonCover]: true,
    [styles.CoverDark]: isMobile,
  })

  const dropdownClassNames = classNames({
    [styles.Dropdown]: true,
    [styles.DropdownOpen]: open && isMobile,
  })

  return (
    <>
      <span onClick={toggleDropdown}>{trigger}</span>
      <Popover
        open={open}
        backdropClassNames={coverClassNames}
        backdropStyle={coverStyle}
        onClose={toggleDropdown}>
        <div
          ref={observe}
          className={dropdownClassNames}
          style={dropdownStyle}
          onClick={toggleDropdown}>
          <div className={styles.Content}>
            {isMobile && (
              <div
                onClick={toggleDropdown}
                className={classNames([
                  styles.MenuClose,
                  styles.IconClose,
                  styles.ButtonIcon,
                ])}
                title='Close'
              />
            )}
            {children}
          </div>
        </div>
      </Popover>
    </>
  )
}

export default Dropdown
