
export type NOTIFICATIONS_BOOKMARK_STATE = {
  page: number,
  sortBy: string
}

export const INIT_STATE: NOTIFICATIONS_BOOKMARK_STATE = {
  page: 1,
  sortBy: null,
}

