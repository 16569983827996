import React from "react"
import styles from '../../../../CSS/main.css'
import { useSettingState } from "./Setting.hooks"

const WebNotificationsTab: React.FC = () => {
    const {
        items,
        isLoading,
        handleChange
    } = useSettingState()

    return (
        <div className={styles.TabWebNotifications}>
            <div className={styles.WebTitle}>Notify me when</div>
            <div>
                <div className={styles.WebOptions}><input onChange={()=> handleChange({likeNotify: !items?.likeNotify})} checked={items?.likeNotify} type="checkbox"/><span>Someone upvotes your comment</span></div>
                {/* <div className={styles.WebOptions}><input type="checkbox"/><span>Someone favorites a discussion you started</span></div> */}
                <div className={styles.WebOptions}><input onChange={()=> handleChange({repliesNotify: !items?.repliesNotify})} checked={items?.repliesNotify} type="checkbox"/><span>Someone comments on a discussion you started</span></div>
            </div>
        </div>
    )
}

export default WebNotificationsTab