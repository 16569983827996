
export type NOTIFICATIONS_STATE = {
  page: number,
  sortBy: string
  tab: string
}

export const NOTIFICATIONS_TABS = [
  {label: 'Most Recent', value: 'all'},
  {label: 'Replies', value: 'replies'},
]

export const INIT_STATE: NOTIFICATIONS_STATE = {
  page: 1,
  sortBy: null,
  tab: NOTIFICATIONS_TABS[0].value
}

