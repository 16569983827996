import React from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'

type ItemProps = {
  readonly onClick?: () => void
  readonly highlighted?: boolean
}
const DropdownItem: React.FC<ItemProps> = ({
  children,
  highlighted,
  onClick,
}) => {
  const labelClasses = classNames({
    [styles.LinkLabel]: true,
    [styles.Delete]: highlighted,
  })

  return (
    <div className={styles.Link} onClick={onClick}>
      <span className={labelClasses}>{children}</span>
    </div>
  )
}

export default DropdownItem
