import React from 'react'
import Row from '../Row'
import { ColOptions, ColSelect } from '../Col'
import Dropdown from '../../Dropdown'
import Button, { ButtonIcon } from '../../Button/Button'
import styles from '../../../../CSS/main.css'

export type BaseProps = {
  readonly onSelected?: () => void
  readonly selected?: boolean
  readonly options?: JSX.Element
  readonly onClick?: () => void
  readonly forbiddenCount?: number
}

const ListItem: React.FC<BaseProps> = ({
  children,
  onSelected,
  selected,
  options,
  onClick,
}) => {
  return (
    <>
      <div className={styles.DivMargin}/>
      <Row onClick={onClick}>
        {Boolean(onSelected) && <ColSelect onChange={onSelected} toggle={selected} />}
        {children}
      </Row>
    </>
  )
}

export default ListItem
