const debounce = <
  Args,
  ReturnType,
  T extends (...args: ReadonlyArray<Args>) => ReturnType
>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  // eslint-disable-next-line functional/no-let
  let timer: number | null = null

  return (...args: Parameters<T>): void => {
    clearTimeout(timer)
    timer = window.setTimeout(
      () => func(...(args as ReadonlyArray<Args>)),
      delay
    )
  }
}

export default debounce
