import React, { useState } from "react"
import styles from '../../../../CSS/main.css'
import Spinner from "../../../routes/Toast/Spinner"
import { useSettingState } from "./Setting.hooks"

const MyCommentsTab: React.FC = () => {
    const {
        items,
        isLoading,
        handleMute,
        handleRemove,
        checkDelete,
        setCheckDelete
    } = useSettingState()

    return (
        <>
            {isLoading
                ?
                <div><Spinner /></div>
                :
                <div>
                    <div className={styles.TabMyComments}>
                        <div className={styles.CommentsTitle}>Visibility</div>
                        <div>
                            <div className={styles.CommentsOptions}><input checked={!items?.muteAllComment && items?.muteAllComment !== null} onChange={() => handleMute(false)} type="radio" name="show" value='false' /><span>Show all my comments</span></div>
                            <div className={styles.CommentsOptions}><input checked={Boolean(items?.muteAllComment)} onChange={() => handleMute(true)} type="radio" name="hide" value='true' /><span>Hide all my comments</span></div>
                        </div>
                    </div>
                    <div className={styles.TabMyComments}>
                        <div className={styles.CommentsTitle}>Delete</div>
                        <div className={styles.CommentsOptionsBottom}>
                            <div className={styles.CommentsOptions}><span>Delete all my comments on all websites</span></div>
                            <div className={styles.CommentsOptions}><span className={styles.Hint}>This action cannot be undone. You will still be able to create new comments but deleted comments will be gone forever.</span></div>
                            <div className={styles.CommentsOptions}><input checked={checkDelete} onChange={() => setCheckDelete(!checkDelete)} type="checkbox" /><span>I understand deleting my comments cannot be undone</span></div>
                            <div className={styles.CommentsOptions}><button disabled={!checkDelete || isLoading} onClick={handleRemove}>Delete</button></div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default MyCommentsTab