import React from "react"
import styles from '../../../../CSS/main.css'
import { useSettingState } from "./Setting.hooks"

const EmailNotificationsTab: React.FC = () => {
    const {
        items,
        isLoading,
        handleChange
    } = useSettingState()

    return (
        <div className={styles.TabEmailNotifications}>
            <div className={styles.EmailNotificationsRow}>
                <div className={styles.EmailTitle}>Enable Emails</div>
                <div className={styles.EmailOptions}>
                    <input type="checkbox"
                        onChange={() => handleChange({ brighteonEmail: !items?.brighteonEmail, replyEmail: !items?.brighteonEmail, subscribeEmail: !items?.brighteonEmail})}
                        checked={items?.brighteonEmail} />
                    <span>Receive emails from Brighteon Comments</span></div>
            </div>
            {Boolean(items?.brighteonEmail) &&
                <>
                    <div className={styles.EmailNotificationsRow}>
                        <div className={styles.EmailTitle}>Replies</div>
                        <div className={styles.EmailOptions}>
                            <input type="checkbox"
                                onChange={() => handleChange({ replyEmail: !items?.replyEmail })}
                                checked={items?.replyEmail} />
                            <span>Receive an emails when someone replies to your commments</span>
                        </div>
                    </div>
                    <div className={styles.EmailNotificationsRow}>
                        <div className={styles.EmailTitle}>Subscribe</div>
                        <div className={styles.EmailOptions}>
                            <input type="checkbox"
                                onChange={() => handleChange({ subscribeEmail: !items?.subscribeEmail })}
                                checked={items?.subscribeEmail} />
                            <span>Subscribe automatically to discussions you comment on</span>
                        </div>
                    </div>
                    <div className={styles.EmailNotificationsHint}>You'll get an email for every new comment on discussions you're subscribed to.</div>
                </>
            }
        </div>
    )
}

export default EmailNotificationsTab
