/* eslint-disable functional/no-conditional-statement */
import { useState, useEffect } from 'react'
import debounce from './debounce'

const useMediaQuery = (query: string, enableListener = true): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = debounce((): void => {
      setMatches(media.matches)
    }, 300)

    enableListener && media.addEventListener('change', listener)
    return () => enableListener && media.removeEventListener('change', listener)
  }, [matches, query])

  return matches
}

export default useMediaQuery
