import React from 'react'
import List, { ListProps } from './List'
import Pagination from '../Pagination'
import Page from '../Page'
import Spinner from '../../routes/Toast/Spinner'

type Props = {
  readonly title: string
  readonly onBack: () => void
  readonly pageCount: number
  readonly page: number
  readonly onPageChange: (page: number) => void
  readonly searchTerm?: string
  readonly breadcrumbActions?: React.ReactNodeArray
  readonly loading: boolean
}

const ListPage = <T,>({
  onBack,
  title,
  page,
  pageCount,
  onPageChange,
  searchTerm,
  breadcrumbActions,
  loading,
  ...listProps
}: ListProps<T> & Props): JSX.Element => {
  return (
    <Page>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <List {...listProps} />
          <Pagination
            total={pageCount}
            page={page}
            onPageChange={onPageChange}
          />
        </>
      )}
    </Page>
  )
}

export default ListPage
