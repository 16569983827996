/* eslint-disable functional/no-conditional-statement */
import React, { EventHandler } from 'react'

const noPropagation =
  <T extends React.MouseEvent>(handler?: EventHandler<T>) =>
  (e: T) => {
    if (handler) {
      handler(e)
      e.stopPropagation()
    }
  }

export default noPropagation
