import React from 'react'
import styles from '../../../CSS/main.css'

type Props = {
  readonly onClick?: () => void
}

const Row: React.FC<Props> = ({ children, onClick }) => {
  return (
    <div className={styles.Row} onClick={onClick}>
      {children}
    </div>
  )
}

export default Row
