import React, { useContext, useEffect, useMemo } from 'react'
import { PortalType, Slot } from '../Portal'
import styles from '../../../CSS/main.css'
import useDimensions from 'react-cool-dimensions'
import { DimensionsContext } from '../../utils/dimensions-context'
import classNames from 'classnames'

const Toolbar: React.VFC = () => {
  const { observe, height, unobserve } = useDimensions<HTMLDivElement>({
    useBorderBoxSize: true,
  })

  const {
    update,
    state: { headerHeight, minimized },
  } = useContext(DimensionsContext)

  useEffect(() => {
    update({ toolbarHeight: height })
  }, [height])

  const dynStyle = useMemo<React.CSSProperties>(
    () => ({
      top: `${headerHeight}px`,
    }),
    [headerHeight]
  )

  const classes = classNames({
    [styles.Toolbar]: true,
    [styles.MinimizedToolbar]: minimized,
  })

  useEffect(() => {
    const cleanup = () => {
      unobserve()
    }
    
    return cleanup
  }, [])

  return (
    <div className={classes} ref={observe} style={dynStyle}>
      <Slot style={dynStyle} name={PortalType.Toolbar} />
    </div>
  )
}

export default Toolbar
