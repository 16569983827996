import React from 'react'
import { PortalType, SlotPortal } from '../Portal'
import ListTable from './ListTable'
import ListHeader from './ListHeader'

export type ListProps<T> = {
  readonly items: ReadonlyArray<T>
  readonly itemRenderer: (i: T) => JSX.Element
  readonly headerRenderer: () => React.ReactNode
}

function List<T>({
  items,
  itemRenderer,
  headerRenderer,
}: ListProps<T>): JSX.Element {
  return (
    <>
      <SlotPortal name={PortalType.Toolbar}>
        <ListHeader>{headerRenderer()}</ListHeader>
      </SlotPortal>
      <ListTable>{items.map(itemRenderer)}</ListTable>
    </>
  )
}

export default List
