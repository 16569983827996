import React, { useState } from 'react'
import Popover from '../Popover'
import styles from '../../../CSS/main.css'
import Button, { ButtonIcon, ButtonIconNoBackGround } from '../Button/Button'
import classNames from 'classnames'
import { CommentTemplateData } from '../../types'
import moment from 'moment'
import { cssImage } from '../List/Item/CommentItem'
import Row from '../List/Row'
import { CommentsBox } from '../CommentBox'

type Props = {
    readonly comment: CommentTemplateData
    readonly onReply: (comment: string, images: ReadonlyArray<string>) => void
    readonly onShowModalImage: (show: boolean, url: string) => void
    readonly closeReplyModal: () => void
}

const ReplyModal: React.FC<Props> = ({ comment, onReply, onShowModalImage, closeReplyModal }) => {

    return (
        <div>
            <div className={styles.RowTitle}>
                <div className={styles.LinkTitle}>
                    {Boolean(comment.title)
                        ? <><a href={comment.slug} target='_blank'>{comment.title}</a></>
                        : <a href={comment.slug} target='_blank'>{comment.slug}</a>
                    }
                </div>
            </div>
            <div className={styles.RowContent}>
                <div className={styles.InfoComment}>
                    <div className={styles.CommentImage}>
                    <img src={comment.avatar} alt="" />
                </div>
                <div className={styles.MainInfo}>
                    <p className={styles.UserInfo}>
                        {comment.displayName} - {moment(comment.createdAt).fromNow()}
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: comment.comment }} />
                    <div className={styles.Media}>
                        {Boolean(comment.images.length) && comment.images.map((url, index) => (
                            <div className={`${styles.MediaCard} ${cssImage(comment.images.length)}`} key={index}>
                                <img src={url} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
                </div>
            </div>
            <CommentsBox onComment={onReply} onShowModalImage={onShowModalImage} closeReplyModal={closeReplyModal} />
        </div>
    )
}

export default ReplyModal
