/* eslint-disable functional/no-conditional-statement */
import React, { useCallback, useMemo, useState } from 'react'
import styles from '../../../CSS/main.css'
import { ButtonIconLeft, ButtonIconRight } from '../Button/Button'
import { function as F, option as O } from 'fp-ts'
import {
  fromInputEvent,
  numToString,
  toEmptyString,
  toInt,
} from '../../utils/fp'

type Props = {
  readonly total: number
  readonly page: number
  readonly onPageChange: (page: number) => void
}

const Pagination: React.FC<Props> = ({ total, page, onPageChange }) => {
  const [innerPageO, setInnerPage] = useState(O.fromNullable(page))

  const onInputChange = useCallback(
    F.flow(fromInputEvent, toInt, setInnerPage),
    []
  )

  const inputValue = useMemo<string>(
    F.flow(
      F.constant(innerPageO),
      O.map(numToString),
      O.getOrElse(toEmptyString)
    ),
    [innerPageO]
  )

  const onSubmit = useCallback(() => {
    const innerPage = O.toNullable(innerPageO)
    const nextPage = innerPage > total ? total : innerPage || 1

    onPageChange(nextPage)
    setInnerPage(O.fromNullable(nextPage))
  }, [innerPageO, page, total])

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const onPrev = useCallback(() => {
    const newPage = page > 1 ? page - 1 : 1
    onPageChange(newPage)
    setInnerPage(O.fromNullable(newPage))
  }, [page, total])

  const onNext = useCallback(() => {
    const newPage = page < total ? page + 1 : total
    onPageChange(newPage)
    setInnerPage(O.fromNullable(newPage))
  }, [page, total])

  if (!total) {
    return null
  }

  return (
    <div className={styles.Table} id={styles.PageNavigation}>
      <div
        className={styles.Col}
        style={{ visibility: page > 1 ? 'visible' : 'hidden' }}>
        {<ButtonIconLeft onClick={onPrev} />}
      </div>
      {total > 1 ? (
        <div className={styles.Col}>
          <input
            id={styles.PageCurrent}
            type='text'
            value={inputValue}
            placeholder=''
            onChange={onInputChange}
            onBlur={onSubmit}
            onSubmit={onSubmit}
            onKeyPress={onKeyPress}
          />
        </div>
      ) : (
        <div className={styles.Col}>1</div>
      )}
      <div className={styles.Col}>of {total}</div>
      <div
        className={styles.Col}
        style={{ visibility: page < total ? 'visible' : 'hidden' }}>
        <ButtonIconRight onClick={onNext} />
      </div>
    </div>
  )
}

export default Pagination
