import React from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import noPropagation from "../../utils/noPropagation";

type BaseProps = {
  readonly additionalClassNames?: ReadonlyArray<string>
  readonly onClick?: (e: React.MouseEvent) => void
}

export const Col: React.FC<BaseProps> = ({
  additionalClassNames = [],
  children,
  onClick,
}) => {
  return (
    <div
      className={classNames([styles.Col, ...additionalClassNames])}
      onClick={onClick}>
      {children}
    </div>
  )
}

type ColSelectProps = {
  readonly onChange: () => void
  readonly toggle: boolean
  readonly showActions?: boolean
}

export const ColSelect: React.FC<ColSelectProps> = ({
  onChange,
  toggle,
  children,
  showActions,
}) => {
  return (
    <Col additionalClassNames={[styles.ColSelect]} onClick={noPropagation((e) => null)}>
      <input
        type='checkbox'
        value='All'
        title='Select All'
        checked={toggle}
        onChange={onChange}
      />
      {showActions && children}
    </Col>
  )
}

export const ColPhoto: React.FC = ({ children }) => {
  return <Col additionalClassNames={[styles.ColPhoto]}>{children}</Col>
}

export const ColInfo: React.FC = ({ children }) => {
  return <Col additionalClassNames={[styles.ColInfo]}>{children}</Col>
}

export const ColStat: React.FC = ({ children }) => {
  return <Col additionalClassNames={[styles.ColStat]}>{children}</Col>
}

export const ColOptions: React.FC = ({ children }) => {
  return <Col additionalClassNames={[styles.ColOptions]}>{children}</Col>
}
