import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import Header from '../Header'
import Toolbar from '../Header/Toolbar'
import Navigation from '../Navigation/Navigation'
import BackToTop from '../BackToTop'
import { Toaster } from 'react-hot-toast'
import { DimensionsContext } from '../../utils/dimensions-context'
import { useAsync } from 'react-async-hook'
import { getCountUnread } from '../../api/requests'
import { getUserLogin } from '../../utils/signin'
import { useNavigation } from '../../utils/navigation'
import { Routes } from '../../routing'
import SettingModal from '../SettingModal'
import ModalLarge from '../ModalLarge'

const useCountUnreadState = (isRefreshCount: boolean) => {
  const fetchUser = useAsync<{ count: number }>(getCountUnread, [isRefreshCount])
  return {
    count: fetchUser.result?.count || 0,
  }
}

const Layout: React.FC<{ children: ReactNode, isRefreshCount?: boolean }> = ({ children, isRefreshCount }) => {
  const { minimized } = useContext(DimensionsContext).state
  const [open, setOpen] = useState<boolean>(false)
  const { goToNotParams } = useNavigation()
  const containerClass = classNames({
    [styles.Container]: true,
    [styles.MinimizedContainer]: minimized,
  })

  const { count } = useCountUnreadState(isRefreshCount)

  useEffect(() => {
    getUserLogin().then((auth) => {
      if (!auth) {
        goToNotParams(Routes.SignIn)
      }
    })
  }, [])

  return (
    <div className={containerClass}>
      <Header unreadCount={count} setOpenSettingModal={() => setOpen(true)}/>
      <Toolbar />
      {open && <ModalLarge open={true} onClose={() => setOpen(false)} children={<SettingModal />} />}
      {children}
      <div>
        <Navigation unreadCount={count} setOpenSettingModal={() => setOpen(true)}/>
      </div>
      <BackToTop />
      <Toaster />
    </div>
  )
}

export default Layout
