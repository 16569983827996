import api from "../api";

export const getUserLogin = async (): Promise<boolean> => {
  try {
    const user = await api.get('/me')
    return Boolean(user)

  } catch (error) {
    return false
  }
}
