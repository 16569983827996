import React from "react";
import styles from "../../../CSS/main.css";
import { CommentTemplateData } from "../../types";
import { EditComment } from "../EditComment";

type Props = {
  readonly comment: CommentTemplateData;
  readonly onEdit: (comment: string, images: ReadonlyArray<string | File>) => void;
  readonly closeEditModal: () => void
  readonly daysLeftToEdit : string;
  readonly onShowModalImage: (show: boolean, url: string) => void
};

const EditCommentModal: React.FC<Props> = ({ comment, onEdit, daysLeftToEdit, closeEditModal, onShowModalImage }) => {
  return (
    <div>
      <div className={`${styles.RowTitle} ${styles.EditTitle}`}>
        Edit Comment
      </div>
      <EditComment
        value={comment.comment}
        images={comment.images}
        onComment={onEdit}
        daysLeftToEdit={daysLeftToEdit}
        onShowModalImage={onShowModalImage}
        id={`${comment.id}`}
        closeEditModal={closeEditModal}
      />
    </div>
  );
};

export default EditCommentModal;
