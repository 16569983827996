import React from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'

const ListTable: React.FC = ({ children }) => {
  return (
    <div className={classNames([styles.Table, styles.ListTable])}>
      {children}
    </div>
  )
}

export default ListTable
