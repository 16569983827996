import React from 'react'
import styles from '../../../CSS/main.css'
import AboutImg from '../../../Images/About-Photo.png'
import { useNavigation } from '../../utils/navigation'
import { Routes } from '../../routing'
const About: React.VFC = () => {
    document.title = 'About'
    const { goToNotParams } = useNavigation()
 
    return (
        <div className={styles.AboutContainer}>
            <nav className={styles.AboutNav}>
                <div className={styles.AboutNavCenter}>
                    <div className={styles.AboutNavCenterLogo} />
                    <div className={styles.AboutNavCenterButtonGroup}>
                        <button onClick={() => { goToNotParams(Routes.SignIn) }} className={styles.AboutNavCenterSignIn}>Sign In</button>
                        <a href='https://www.brighteon.com/dashboard/request-invitation' target='_blank' className={styles.AboutNavCenterSignUp}>Create an Account</a>
                    </div>
                </div>
            </nav>
            <div className={styles.AboutContentContainer}>
                <img className={styles.AboutContentContainerImage} src={AboutImg}/>
                <div className={styles.AboutContentContainerText}>
                    <p className={styles.AboutContentTitle}>Our new comment system allows people to express themselves freely without any censorship.</p>
                    <p className={styles.AboutContentText}>Here at Brighteon, we are dedicated to promoting free speech and liberty and we value the importance of open communication. But since our original commenting system has been prone to heavy censorship in the past, we've implemented some new changes to ensure that every voice is heard and differing opinions will not be suppressed.</p>
                    <p className={styles.AboutContentText}>To fight against the evils of censorship, we will no longer be using our old Disqus commenting system. Instead, we have now launched our new and improved independent commenting system, which does not rely on Big Tech to function. With Big Tech actively silencing any discussion that goes against the narrative of mainstream media, it is now more important than ever to get full control over your words and the people you get to connect with.</p>
                    <p className={styles.AboutContentText}>That's why we specifically created our new commenting system to allow you to fully engage in meaningful conversation without any fear of censorship or suppression. As such, this new system will be tied to your Brighteon account. If you do not already have a Brighteon account, you can <a href='https://www.brighteon.com/dashboard/request-invitation' target='_blank'>create one for free</a>.</p>
                    <p className={styles.AboutContentText}>With this new commenting system, we hope to achieve our goal of connecting all of our associated websites so that our users can easily log in between Natural News, NewsTarget, Brighteon.Social and Brighteon with one single account.</p>
                </div>
            </div>
        </div>
    )
}

export default About