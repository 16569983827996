import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from '../../../CSS/main.css'
import debounce from '../../utils/debounce'

const BackToTop: React.FC = () => {
  const [showBtn, setShowBtn] = useState(false)

  const checkWindowScroll = useCallback(
    debounce((): void => setShowBtn(window.pageYOffset > 100), 100),
    []
  )

  useEffect(() => {
    window.addEventListener('scroll', checkWindowScroll)
    return () => {
      window.removeEventListener('scroll', checkWindowScroll)
    }
  }, [])

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const dynamicStyle = useMemo((): React.CSSProperties => {
    return {
      transition: 'opacity 0.5s',
      opacity: showBtn ? 1 : 0,
      display: 'block',
    }
  }, [showBtn])

  return (
    <div
      id={styles.BacktoTop}
      style={dynamicStyle}
      className={classNames([styles.ButtonIcon, styles.IconBacktoTop])}
      onClick={scrollToTop}
      title='Back to Top'
    />
  )
}

export default BackToTop
