
export type COMMENT_STATE = {
  page: number,
  sortBy: string
}

export const INIT_STATE: COMMENT_STATE = {
  page: 1,
  sortBy: null,
}
