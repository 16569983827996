import React from 'react'
import { hot } from 'react-hot-loader'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-quill/dist/quill.snow.css';

import { AppRouter } from './AppRouter'
import { SlotPortalProvider } from './Components/Portal'
import { DimensionsProvider } from './utils/dimensions-context'
import { ThemeProvider } from './Components/ThemeSwitcher'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()
// eslint-disable-next-line functional/no-class
class App_ extends React.Component<
  Record<string, never>,
  Record<string, never>
> {
  render(): JSX.Element {
    return (
      <DimensionsProvider>
        <SlotPortalProvider>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </ThemeProvider>
        </SlotPortalProvider>
      </DimensionsProvider>
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const module: any
module.hot && module.hot.accept()

export const App = hot(module)(App_)
