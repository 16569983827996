import { ListParams, RequestParams } from "../Components/List/hooks";
import { COMMENT_STATE, CommentTemplateData, GetPaginatedResponse, HandleList, NotificationTemplateData, PostCommentActionParams, PostReplyParams, SettingTemplateData, UnblockUsers, UpdateNotificationSettings, User, UserBlockFilter, UserBlockTemplateData } from "../types";
import api from "./index";

export const getMe = (): Promise<User> => {
  return api.get<User>('/me')
}

export const signout = (): Promise<{ link: string }> => {
  return api.get(`/signout?origin=${document.location.href}`)
}

export const getComments = (params: RequestParams<ListParams>): Promise<GetPaginatedResponse<CommentTemplateData>> => {
  return api.get<GetPaginatedResponse<CommentTemplateData>>('/user/comments', {
    params,
  })
}

export const getUserBlock = (params: UserBlockFilter): Promise<GetPaginatedResponse<UserBlockTemplateData>> => {
  return api.get<GetPaginatedResponse<UserBlockTemplateData>>('/user/blocks', {
    params,
  })
}

export const getNotifications = (params: RequestParams<ListParams>): Promise<GetPaginatedResponse<NotificationTemplateData>> => {
  return api.get<GetPaginatedResponse<NotificationTemplateData>>('/notifications', {
    params,
  })
}

export const getSearchNotifications = (params: RequestParams<ListParams>): Promise<GetPaginatedResponse<NotificationTemplateData>> => {
  return api.get<GetPaginatedResponse<NotificationTemplateData>>('/notifications/search', {
    params,
  })
}

export const getBookmarks = (params: RequestParams<ListParams>): Promise<GetPaginatedResponse<NotificationTemplateData>> => {
  return api.get<GetPaginatedResponse<NotificationTemplateData>>('/bookmarks', {
    params,
  })
}

export const muteCommentList = ({ value, authorId, ids }: HandleList): Promise<void> => {
  return api.post(`/display/comments`, { value, authorId, ids })
}

export const bookmarkNotificationList = ({ value, authorId, ids }: HandleList): Promise<void> => {
  return api.post(`/notifications/bookmark`, { value, authorId, ids })
}

export const readNotificationList = ({ value, authorId, ids }: HandleList): Promise<void> => {
  return api.post(`/notifications/read`, { value, authorId, ids })
}

export const deleteNotificationList = ({ authorId, ids }: HandleList): Promise<void> => {
  return api.post(`/notifications/delete`, { authorId, ids })
}

export const deleteCommentList = (ids: ReadonlyArray<number>): Promise<void> => {
  return api.post(`/delete/comments`, { ids })
}


export const postReply = (replyData: PostReplyParams): Promise<void> => {
  const formData = new FormData();
  Object.keys(replyData).forEach(key => {
    if (key !== 'images' && replyData[key]) formData.append(key, replyData[key])
  })
  if (replyData.images.length) replyData.images.forEach(image => {
    formData.append('files', image)
  })
  return api.post(`/comments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const editComment = ({id, comment, images}: { id: number, comment: string, images: ReadonlyArray<string | File> }): Promise<void> => {
  const formData = new FormData();
  if (images.length) images.forEach(image => {
    if (typeof image === 'string') {
      formData.append('images', image)
    } else {
      formData.append('files', image)
    }
  })
  formData.append('comment', comment)
  return api.post(`/comments/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getCountUnread = (): Promise<{ count: number }> => {
  return api.get<{ count: number }>(`/notifications/unread-count`)
}

export const postCommentAction = (p: PostCommentActionParams): Promise<{ count: number }> =>{
  return api.post(`/comment/${p.id}/${p.action}`, { authorId: p.authorId })
}

export const removeAllComments = (): Promise<void> => {
  return api.post(`/settings/delete`)
}

export const muteAllComments = (value: boolean): Promise<void> => {
  return api.post(`/settings/mute`, { value })
}

export const updateNotificationSettings = ({ likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail }: UpdateNotificationSettings): Promise<void> => {
  const dcm = { likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail }
  return api.post(`/settings`, { likeNotify, repliesNotify, brighteonEmail, replyEmail, subscribeEmail })
}

export const getSetting = (): Promise<SettingTemplateData> => {
  return api.get<SettingTemplateData>('/settings')
}

export const unblockUsers = ({ blockIds }: UnblockUsers): Promise<void> => {
  return api.post(`/user/unblocks`, { blockIds })
}
