import React from 'react'
import {Link, LinkProps} from 'react-router-dom'
import {LocationState} from 'history'

export enum Routes {
  Home = '/',
  Demo = '/demo',
  Comments = '/comments',
  Notifications = '/notifications',
  Bookmarks = '/bookmarks',
  About = '/about',
  SignIn = '/signin',
  Search = '/search'
}

// @ts-ignore
export type ExtractRouteParams<T = Routes> = any

  // this breaks because of TS versions mismatch
  // string extends T
  // ? Record<string, string>
  // : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  // ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  // : T extends `${infer _Start}:${infer Param}`
  // ? { [k in Param]: string }
  // : {}

export const urlWithParams = (url: Routes, params: ExtractRouteParams) => {
  return Object.keys(params).reduce((u, key) => {
    return u.replace(`:${key}`, params[key])
  }, url)
}

type Props = React.PropsWithoutRef<LinkProps<LocationState>> &
  React.RefAttributes<HTMLAnchorElement> & {
    to: Routes
    params?: ExtractRouteParams
  }

/* eslint-enable */

export const TypedLink: React.FC<Props> = ({
  to,
  children,
  params = {},
  ...rest
}) => {
  const url = urlWithParams(to, params)

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  )
}
