/* eslint-disable functional/no-expression-statement,functional/no-return-void */
import React from 'react'
import { useCallback, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from '../../../CSS/main.css'
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import imageCompression from "browser-image-compression";

const MAX_LENGTH_COMMENT = 1000

type CommentsBoxPropsActions = {
  readonly onComment: (comment: string, images: ReadonlyArray<string>) => void
  readonly onShowModalImage?: (isShow: boolean, url: string | null) => void;
  readonly closeReplyModal?: () => void;
}

type Emoji = {
  emoji: string
}

const MAX_IMAGE_ALLOW = 1;

const maxPictureSizeInMB = 2;
const maxPictureSize = maxPictureSizeInMB * 1024 * 1024;

export function CommentsBox(props: CommentsBoxPropsActions): JSX.Element {

  const setLengthCommentBox = (value: EditorValue, isImage?: boolean) => {

    const count = value
      .getEditorState()
      .getCurrentContent()
      .getPlainText()
      .trim()
      .length
    setState((crrState) => ({
      ...crrState,
      isShowCancelButton: count > 0 || isImage,
      isLarge: count > MAX_LENGTH_COMMENT,
      characterCount: count
    }))
  }

  const
    initialValue = RichTextEditor.createEmptyValue(),
    initialCount = initialValue
      .getEditorState()
      .getCurrentContent()
      .getPlainText()
      .trim()
      .length,
    [state, setState] = useState({
      message: initialValue,
      showEmoji: false,
      showImageButton: false,
      errorImage: '',
      isLarge: initialCount > MAX_LENGTH_COMMENT,
      characterCount: initialCount,
      isShowCancelButton: initialCount > 0,
      inputImage: '',
      listImage: [],
      isPublishing: false,
    })
  const { message, showEmoji, isLarge, characterCount, isShowCancelButton, errorImage, listImage, 
    isPublishing } = state
  const
    onInput = useCallback((
      value: EditorValue
    ) => {

      setState((crrState) => ({
        ...crrState,
        message: value,
        errorImage: ''
      }))

      setLengthCommentBox(value, Boolean(listImage.length))
    }, [listImage]),
    onEmpty = useCallback(() => {
      props?.closeReplyModal();
      setState((crrState) => ({
        ...crrState,
        message: RichTextEditor.createEmptyValue(),
        isShowCancelButton: false,
        listImage: []
      }))
    }, []),
    setIsPublishing = useCallback(
      (isPublishing) => {
        setState((crrState) => ({
          ...crrState,
          isPublishing,
        }));
      },
      [state]
    ),
    onPublish = useCallback(async () => {
      setIsPublishing(true);
      await props.onComment(message.toString('html'), listImage)
      setIsPublishing(false);
      onEmpty()
    }, [props.onComment, message, listImage, onEmpty]),
    placeHolder = 'What\'s on your mind...'

  const setEmoji = (emojiObject: EmojiClickData, event: MouseEvent) => {
    const newMessage = message.toString('html') === '<p><br></p>'
      ? RichTextEditor.createValueFromString(`<p>${emojiObject.emoji}</p>`, 'html')
      : RichTextEditor.createValueFromString(message.toString('html').replace('</p>', `${emojiObject.emoji}</p>`), 'html')

    setState((crrState) => ({
      ...crrState,
      message: newMessage,
      showEmoji: false
    }))
    setLengthCommentBox(newMessage)
  }

  const setShowEmoji = (isShow: boolean) => {
    setState((crrState) => ({
      ...crrState,
      showEmoji: isShow
    }))
  }

  const removeImage = (index: number) => {
    const newList = listImage.filter((url, ind) => ind !== index);
    setState((crrState) => ({
      ...crrState,
      listImage: newList,
    }));
    setLengthCommentBox(message, Boolean(newList.length));
  };

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles || !acceptedFiles.length) return;
      const compressionOption = {
        maxSizeMB: 1,
      };
      const processedFile =
        acceptedFiles[0].size > 1024 * 1024
          ? await imageCompression(acceptedFiles[0], compressionOption)
          : acceptedFiles[0];
      setState((crrState) => ({
        ...crrState,
        listImage: [...listImage, processedFile],
        errorImage: "",
      }));
      setLengthCommentBox(message, true);
    },
    [listImage]
  );

  const onImageDropReject = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    const errorMessage =
      fileRejections[0].errors[0].message === "Too many files"
        ? "Too many files. Maximum number of images allowed: 1"
        : "File is too big. Maximum image size allowed: 2MB";
    setState((crrState) => ({
      ...crrState,
      errorImage: errorMessage,
    }));
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const plainText = event.clipboardData.getData('text/plain');
    
    const newValue = RichTextEditor.createValueFromString(plainText, 'html');

    setState((crrState) => ({
      ...crrState,
      message: newValue,
      errorImage: ''
    }))
  };

  return (
    <>
      <div className={`${styles.NewComment} ${styles.CommentBox}`}>
        <div className={styles.Input}>
          {showEmoji && <div className={styles.EmojiPick}>
            <EmojiPicker onEmojiClick={setEmoji} height={420} width={300}/>
          </div>}
          <div className={styles.ButtonWrap}>
            <Dropzone
                accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
                disabled={listImage.length === MAX_IMAGE_ALLOW}
                maxFiles={MAX_IMAGE_ALLOW}
                maxSize={maxPictureSize}
                onDrop={onImageDrop}
                onDropRejected={onImageDropReject}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <button
                      {...getRootProps()}
                      className={styles.ButtonImage}
                      disabled={listImage.length === MAX_IMAGE_ALLOW}
                    >
                      <span className={`${styles.IconImage} ${styles.IconButton}`} />
                    </button>
                    <input {...getInputProps()} />
                  </>
                )}
              </Dropzone>
          </div>
          <div onPaste={handlePaste}>
            <RichTextEditor
              placeholder={placeHolder}
              value={message}
              onChange={onInput}
              toolbarClassName={styles.RichTextEditorToolbar}
              toolbarConfig={{
                display: ['LINK_BUTTONS', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
                INLINE_STYLE_BUTTONS: [
                  { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                  { label: 'Italic', style: 'ITALIC' },
                  { label: 'Underline', style: 'UNDERLINE' }
                ],
                BLOCK_TYPE_DROPDOWN: [
                  { label: 'Normal', style: 'unstyled' },
                  { label: 'Heading Large', style: 'header-one' },
                  { label: 'Heading Medium', style: 'header-two' },
                  { label: 'Heading Small', style: 'header-three' }
                ],
                BLOCK_TYPE_BUTTONS: [
                  { label: 'UL', style: 'unordered-list-item' },
                  { label: 'OL', style: 'ordered-list-item' }
                ],
              } as unknown as ToolbarConfig}
            />
          </div>
          {Boolean(errorImage) && <div className={styles.ErrorBox}>
            {errorImage}
          </div>}
          {Boolean(listImage.length) && (
            <div className={styles.ImageBox}>
              {listImage.map((image, index) => {
                const imageUrl =
                  typeof image === "string"
                    ? image
                    : URL.createObjectURL(image);
                return (
                  <div className={styles.ImageItem} key={index}>
                    <div
                      className={styles.ImageRemove}
                      onClick={() => removeImage(index)}
                    />
                    <img
                      src={imageUrl}
                      onClick={() => props.onShowModalImage(true, imageUrl)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.EmojiBox}>
            <button
              className={styles.EmojiButton}
              onClick={() => setShowEmoji(!showEmoji)}
              style={{ border:'none', backgroundColor:'transparent' }}
            >
              😂
            </button>
          </div>
        </div>
        <div className={styles.Buttons}>
          <div>
            <button disabled={isLarge || !isShowCancelButton || !message} className={`${styles.Button} ${styles.ButtonPublish}`} title="Publish"
              onClick={onPublish}>
              {isPublishing ? 'Saving ...' : 'Comment'}
            </button>
          </div>
          <div>
            <button className={`${styles.ButtonText} ${styles.ButtonCancel} ${isShowCancelButton ? '' : styles.ButtonHide}`}
              title="Clear" onClick={onEmpty}>Clear
            </button>
          </div>
          <div>
            <div className={`${isLarge ? styles.Error : styles.ButtonHide}`}>
              {characterCount}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
