import React from 'react'
import {SpinnerDotted} from "spinners-react";

export const SPINNER_COLOR = '#0071bd'

const Spinner: React.VFC = () => {
  return (
    <div style={{height: '20px', display: 'flex', justifyContent: 'center'}}>
      <SpinnerDotted color={SPINNER_COLOR}/>
    </div>
  )
}

export default Spinner
