import React, { useCallback } from 'react'
import styles from '../../../CSS/main.css'
import Layout from '../../Components/Layout'
import ListPage from '../../Components/List/ListPage'
import { Col, ColInfo, ColOptions, ColPhoto, ColSelect, ColStat } from '../../Components/List/Col'
import Dropdown from '../../Components/Dropdown'
import { ButtonIcon, ButtonIconSquare } from '../../Components/Button/Button'
import DropdownGroup from '../../Components/Dropdown/DropdownGroup'
import DropdownItem from '../../Components/Dropdown/DropdownItem'
import { useListState } from '../../Components/List/hooks'
import { CommentTemplateData, HandleList } from '../../types'
import { COMMENT_STATE, INIT_STATE } from './filters'
import { getComments, muteCommentList } from '../../api/requests'
import CommentsItem from '../../Components/List/Item/CommentItem'
import { useAsyncCallback } from 'react-async-hook'
import Modal from '../../Components/Modal'
import ReplyModal from '../../Components/ReplyModal'
import EditCommentModal from '../../Components/EditCommentModal'
import { addDays, formatDistance, isAfter } from 'date-fns'
import { MediaModal } from '../../Components/MediaModal'

const MAX_DAYS_FROM_CREATE = 7

const Comments: React.VFC = () => {
    document.title = 'Comments'
    const {
        params,
        select,
        toggleAll,
        selectedItems,
        items,
        pageCount,
        loading,
        allSelected,
        onPageChange,
        refetch,
        setListParams,
        handleCommentAction,
        open,
        openEdit,
        commentModal,
        onChangeOpen,
        onChangeOpenEdit,
        handlePostReply,
        handleEditComment,
        handleOpenModal,
        handleDeleteComments,
        modalImageControl,
        handleModalImage,
    } = useListState<CommentTemplateData, COMMENT_STATE>(INIT_STATE, getComments)
    const { page } = params
    const performMuteAction = useAsyncCallback(muteCommentList)

    const onMute = useCallback(async ({ ids, value, authorId }: HandleList) => {
        await performMuteAction.execute({ ids, value, authorId })
        refetch()
    }, [refetch])

    const handleMute = useCallback(async ({ ids, value, authorId }: HandleList) => {
        await onMute({ ids, value, authorId })
    }, [onMute])

    const daysLeftToEdit = useCallback((comment : CommentTemplateData) => {
        if (!comment.id) return ''
    
        const createdAt = new Date(comment.createdAt)
        const maxDaysAfterCreate = addDays(createdAt, MAX_DAYS_FROM_CREATE)
        const currentDate = new Date()
    
        if (isAfter(currentDate, maxDaysAfterCreate)) return ''
    
        return formatDistance(currentDate, maxDaysAfterCreate)
    }, [])

    return (
        <Layout>
            {commentModal && <Modal open={open} onClose={onChangeOpen} children={<ReplyModal onShowModalImage={handleModalImage} onReply={handlePostReply} comment={commentModal} closeReplyModal={onChangeOpen} />} />}
            {commentModal && daysLeftToEdit(commentModal) && 
                <Modal
                    open={openEdit}
                    onClose={onChangeOpenEdit}
                    children={
                        <EditCommentModal
                            closeEditModal={onChangeOpenEdit}
                            daysLeftToEdit={daysLeftToEdit(commentModal)}
                            onEdit={handleEditComment} comment={commentModal}
                            onShowModalImage={handleModalImage}
                        />
                    }
                />
            }
            {modalImageControl.show && (
                <MediaModal
                    urlImage={modalImageControl.url}
                    isShowModalMedia={modalImageControl.show}
                    setShowModalMedia={handleModalImage}
                />
            )}
            <ListPage
                loading={loading}
                items={items}
                itemRenderer={(i) => (
                    <CommentsItem
                        key={i.id}
                        onSelected={() => select(i)}
                        selected={selectedItems.includes(i)}
                        comment={i}
                        handleCommentAction={handleCommentAction}
                        options={
                            <>
                                <DropdownGroup>
                                    <DropdownItem onClick={() => handleOpenModal(i)}>Reply</DropdownItem>
                                    <DropdownItem onClick={() => handleMute({ ids: [i.id], value: true, authorId: i.userId })}>Hide</DropdownItem>
                                    <DropdownItem onClick={() => handleMute({ ids: [i.id], value: false, authorId: i.userId })}>Show</DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteComments([i.id])} highlighted>Delete</DropdownItem>
                                    {daysLeftToEdit(i) && <DropdownItem onClick={() => handleOpenModal(i, true)}>Edit</DropdownItem>}
                                </DropdownGroup>
                            </>
                        }
                    />
                )}
                headerRenderer={() => (
                    <>
                        <ColSelect
                            onChange={toggleAll}
                            showActions={!!selectedItems.length}
                            toggle={allSelected}>
                            <Dropdown
                                trigger={
                                    <ButtonIcon
                                        title='Select Options'
                                        additionalClassNames={[styles.SelectOptions]}
                                    />
                                }
                                position={'right'}
                                topDown={-20}
                            >
                                <DropdownGroup>
                                    <DropdownItem onClick={() => handleMute({ ids: selectedItems.map(i => i.id), value: true, authorId: selectedItems[0]?.userId })}>Hide</DropdownItem>
                                    <DropdownItem onClick={() => handleMute({ ids: selectedItems.map(i => i.id), value: false, authorId: selectedItems[0]?.userId })}>Show</DropdownItem>
                                    <DropdownItem onClick={() => handleDeleteComments(selectedItems.map(i => i.id))} highlighted>Delete</DropdownItem>
                                </DropdownGroup>
                            </Dropdown>
                        </ColSelect>
                        <ColInfo>
                            <span className={styles.TabTitle}>My Comments</span>
                        </ColInfo>
                        <ColOptions>
                            <Dropdown
                                trigger={<ButtonIconSquare additionalClassNames={[styles.IconFilter]} />}
                                position={'left'}
                                topDown={-20}
                                leftDown={-30}
                            >
                                <DropdownGroup>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'new'})}}>Newest</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'old'})}}>Oldest</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'up'})}}>Upvotes</DropdownItem>
                                    <DropdownItem onClick={()=> {setListParams({...params, sortBy: 'down'})}}>Downvotes</DropdownItem>
                                </DropdownGroup>
                            </Dropdown>
                        </ColOptions>
                    </>
                )}
                title={'Manage Comments'}
                onBack={null}
                pageCount={pageCount}
                page={page}
                onPageChange={onPageChange}
            />
        </Layout>
    )
}

export default Comments