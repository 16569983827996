import css from '../../../CSS/main.css'

type CSS = typeof css & {
  readonly 'is-dark': string
  readonly 'is-light': string
}

const cssWithTheme = css as CSS

export enum Theme {
  Auto = 'auto',
  Light = 'light',
  Dark = 'dark',
}

export const switchTheme = (t: Theme): void => {
  const html = document.documentElement

  // eslint-disable-next-line functional/no-conditional-statement
  switch (t) {
    case Theme.Auto:
      html.classList.remove(cssWithTheme['is-dark'], cssWithTheme['is-light'])
      break
    case Theme.Light:
      html.classList.add(cssWithTheme['is-light'])
      html.classList.remove(cssWithTheme['is-dark'])
      break
    case Theme.Dark:
      html.classList.add(cssWithTheme['is-dark'])
      html.classList.remove(cssWithTheme['is-light'])
      break
  }
}
