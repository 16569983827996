import React, { Dispatch, useCallback, useReducer } from 'react'
import ReactDOM from 'react-dom'

export enum PortalType {
  Toolbar = 'Toolbar',
  Burger = 'Burger',
}

type PortalAction = {
  readonly type: PortalType
  readonly ref: HTMLElement
}

type PortalState = {
  readonly [key in PortalType]?: HTMLElement | null
}

type SlotContext = {
  readonly dispatch: Dispatch<PortalAction>
  readonly state: PortalState
}

export const Context = React.createContext<SlotContext>({
  state: {},
  dispatch: () => null, // no-op
})

const initialPortalState: PortalState = {}
const portalReducer: React.Reducer<PortalState, PortalAction> = (
  state,
  action
) => {
  // eslint-disable-next-line functional/no-conditional-statement
  switch (action.type) {
    case PortalType.Toolbar: {
      const { ref } = action
      return {
        ...state,
        [PortalType.Toolbar]: ref,
      }
    }
    case PortalType.Burger: {
      const { ref } = action
      return {
        ...state,
        [PortalType.Burger]: ref,
      }
    }
    default:
      return { ...state }
  }
}

export const SlotPortalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(portalReducer, initialPortalState)
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export const useSlot = (
  slotName: PortalType
): readonly [HTMLElement, React.RefCallback<HTMLElement>] => {
  const { state, dispatch } = React.useContext(Context)
  return [
    state[slotName],
    (ref) => {
      dispatch({ type: slotName, ref })
    },
  ]
}

export type SlotProps = {
  readonly className?: string
  readonly name: PortalType
}

export const Slot: React.VFC<SlotProps & React.HTMLProps<HTMLDivElement>> = ({
  className,
  name,
  ...rest
}) => {
  const [, setSlot] = useSlot(name)
  const refMemo = useCallback(setSlot, [name])
  return <div className={className} ref={refMemo} {...rest} />
}

export type SlotPortalProps = {
  readonly name: PortalType
}

export const SlotPortal: React.FC<SlotPortalProps> = ({ name, children }) => {
  const [slotRef] = useSlot(name)
  return slotRef ? ReactDOM.createPortal(children, slotRef) : null
}

export type NormalPortalProps = {
  readonly portalId?: string
}

const Portal: React.FC<NormalPortalProps> = ({ children, portalId }) => {
  const modalPortalRoot = document.getElementById(portalId || 'modal-portal')
  return ReactDOM.createPortal(children, modalPortalRoot)
}

export default Portal
