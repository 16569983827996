import React, { useState } from 'react'
import styles from '../../../CSS/main.css'
import WebNotificationsTab from './SettingTabs/WebNotificationsTab'
import EmailNotificationsTab from './SettingTabs/EmailNotificationsTab'
import BlockUserTab from './SettingTabs/BlockUserTab'
import MyCommentsTab from './SettingTabs/MyCommentsTab'

type Tab = {
    value: string,
    label: string,
    component: React.JSX.Element
}

const listTab = [
    {
        value: 'web',
        label: 'Web Notifications',
        component: <WebNotificationsTab />
    },
    {
        value: 'email',
        label: 'Email Notifications',
        component: <EmailNotificationsTab/>
    },
    {
        value: 'block',
        label: 'Blocked Users',
        component: <BlockUserTab />
    },
    {
        value: 'comment',
        label: 'My Comments',
        component: <MyCommentsTab />
    }
]

const SettingModal: React.FC = () => {

    const [tab, setTab] = useState<Tab>(listTab[0])
    const [open, setOpen] = useState<boolean>(false)

    const changeTab = (item: Tab) => {
        setTab(item)
        setOpen(false)
    }

    return (
        <div className={styles.ModalSetting}>
            <div className={styles.SettingTile}>
                Settings
            </div>
            <div className={styles.SettingContent}>
                <div className={styles.SettingDropdown} onClick={()=> setOpen(!open)}>
                    <span>{tab.label}</span>
                    <div className={styles.IconDropDownSetting}/>
                </div>
                <div className={styles.ListTab} style={{ display: open ? 'block' : '' }}>
                    {listTab.map(item => (
                        <div key={item.value}>
                            <span 
                                style={{color: item.value === tab.value ? 'blue' : ''}}
                                onClick={() => changeTab(item)}
                            >
                                {item.label}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={styles.TabContent}>
                    {tab.component}
                </div>
            </div>
        </div>
    )
}

export default SettingModal