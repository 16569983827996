import React, { useCallback } from 'react'
import styles from '../../../CSS/main.css'
import classNames from 'classnames'
import { useNavigation } from '../../utils/navigation'
import { Routes } from '../../routing'

type Props = {
  readonly name?: string
  readonly type?: string
  readonly placeholder?: string
  readonly value: string
  readonly onChange: (text: string) => void
  readonly notice?: string
}

const Input: React.VFC<Props> = ({
  name = '',
  type = 'text',
  placeholder,
  value,
  onChange,
  notice,
}) => {

  const { goToHref } = useNavigation()

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChange(ev.target.value)
    },
    [onChange]
  )

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      goToHref(`${Routes.Search}?query=${value}`)
    }
  };

  return (
    <div className={styles.Input}>
      <div className={classNames([styles.IconSearch, styles.Icon])} onClick={()=> goToHref(`${Routes.Search}?query=${value}`)}/>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {!!notice && <span className={styles.Notice}>{notice}</span>}
    </div>
  )
}

export default Input
