/* eslint-disable functional/no-expression-statement,functional/no-return-void */
import React from "react";
import { useCallback, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as css from "../../../CSS/main.css";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import ReactQuill from "react-quill";
import QuillToolbar, { formats } from "../EditorToolbar";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import imageCompression from "browser-image-compression";
import usePasteAsPlainText from "../../utils/usePasteAsPlainText";

const MAX_LENGTH_COMMENT = 4000;

type EditCommentsProps = {
  readonly isReply?: boolean;
  readonly value?: string;
  readonly images?: ReadonlyArray<string | File>;
  readonly id?: string;
  readonly daysLeftToEdit: string;
};

type EditCommentPropsActions = {
  readonly onComment: (
    message: string,
    images: ReadonlyArray<string | File>
  ) => void;
  readonly onShowModalImage?: (isShow: boolean, url: string | null) => void;
  readonly setIsShowReplyBox?: (isShow: boolean) => void;
  readonly closeEditModal?: () => void;
};

type Emoji = {
  emoji: string;
};

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

const MAX_IMAGE_ALLOW = 1;

const maxPictureSizeInMB = 2;
const maxPictureSize = maxPictureSizeInMB * 1024 * 1024;

export function EditComment(
  props: EditCommentsProps & EditCommentPropsActions
): JSX.Element {

  usePasteAsPlainText()

  const setLengthCommentBox = (value: string, isImage?: boolean) => {
    const count = value.replace(/<(.|\n)*?>/g, "").trim().length;
    setState((crrState) => ({
      ...crrState,
      isShowCancelButton: count > 0 || isImage,
      isLarge: count > MAX_LENGTH_COMMENT,
    }));
  };

  const initialValue = props.value || "",
    initialCount = initialValue.replace(/<(.|\n)*?>/g, "").trim().length,
    [state, setState] = useState({
      message: initialValue,
      showEmoji: false,
      errorImage: "",
      isLarge: initialCount > MAX_LENGTH_COMMENT,
      isShowCancelButton: initialCount > 0,
      listImage: props.images || [],
      isPublishing: false,
    });
  const {
    message,
    showEmoji,
    isLarge,
    isShowCancelButton,
    errorImage,
    listImage,
    isPublishing,
  } = state;
  const onInput = useCallback(
      (value: string) => {
        setState((crrState) => ({
          ...crrState,
          message: value,
          errorImage: "",
        }));

        setLengthCommentBox(value, Boolean(listImage.length));
      },
      [state]
    ),
    onEmpty = useCallback(() => {
      props?.closeEditModal();
      setState((crrState) => ({
        ...crrState,
        message: "",
        isShowCancelButton: false,
        listImage: [],
      }));

      if (props.isReply) {
        props.setIsShowReplyBox(false);
      }
    }, []),
    setIsPublishing = useCallback(
      (isPublishing) => {
        setState((crrState) => ({
          ...crrState,
          isPublishing,
        }));
      },
      [state]
    ),
    onPublish = useCallback(async () => {
      setIsPublishing(true);
      await props.onComment(message, listImage);
      setIsPublishing(false);
      onEmpty();
    }, [props.onComment, message, listImage, onEmpty, isPublishing]),
    placeHolder = props.isReply
      ? "Reply to this comment"
      : "What's on your mind...";

  const setEmoji = (emojiObject: Emoji) => {
    const newMessage =
      message === "<p><br></p>"
        ? `<p>${emojiObject.emoji}</p>`
        : message.replace("</p>", `${emojiObject.emoji}</p>`);

    setState((crrState) => ({
      ...crrState,
      message: newMessage,
      showEmoji: false,
    }));
    setLengthCommentBox(newMessage);
  };

  const setShowEmoji = (isShow: boolean) => {
    setState((crrState) => ({
      ...crrState,
      showEmoji: isShow,
    }));
  };

  const removeImage = (index: number) => {
    const newList = listImage.filter((url, ind) => ind !== index);
    setState((crrState) => ({
      ...crrState,
      listImage: newList,
    }));
    setLengthCommentBox(message, Boolean(newList.length));
  };

  const onImageDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles || !acceptedFiles.length) return;
      const compressionOption = {
        maxSizeMB: 1,
      };
      const processedFile =
        acceptedFiles[0].size > 1024 * 1024
          ? await imageCompression(acceptedFiles[0], compressionOption)
          : acceptedFiles[0];
      setState((crrState) => ({
        ...crrState,
        listImage: [...listImage, processedFile],
        errorImage: "",
      }));
      setLengthCommentBox(message, true);
    },
    [listImage]
  );

  const onImageDropReject = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    const errorMessage =
      fileRejections[0].errors[0].message === "Too many files"
        ? "Too many files. Maximum number of images allowed: 1"
        : "File is too big. Maximum image size allowed: 2MB";
    setState((crrState) => ({
      ...crrState,
      errorImage: errorMessage,
    }));
  };

  const modules = {
    toolbar: {
      container: `#toolbar-${props.id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  return (
    <div className={css.CommentsBrighteon}>
      <div
        className={`${props.isReply ? "" : css.NewComment} ${css.CommentBox}`}
      >
        <div className={css.Input}>
          <div className={css.EditBoxAndEmoji}>
            <ReactQuill
              placeholder={placeHolder}
              theme="snow"
              modules={modules}
              formats={formats}
              value={message}
              onChange={onInput}
              className={css.EditorBox}
            />
            <div className={css.EmojiBox}>
              {showEmoji && (
                <div className={css.EmojiPick}>
                  <EmojiPicker
                    lazyLoadEmojis={true}
                    onEmojiClick={(
                      emojiData: EmojiClickData,
                      event: MouseEvent
                    ) => setEmoji(emojiData)}
                  />
                </div>
              )}
              <button
                className={css.EmojiButton}
                onClick={() => setShowEmoji(!showEmoji)}
              >
                😂
              </button>
            </div>
          </div>
          <QuillToolbar
            id={props.id}
            imageButton={
              <Dropzone
                accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
                disabled={listImage.length === MAX_IMAGE_ALLOW}
                maxFiles={MAX_IMAGE_ALLOW}
                maxSize={maxPictureSize}
                onDrop={onImageDrop}
                onDropRejected={onImageDropReject}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <button
                      {...getRootProps()}
                      className={css.ButtonImage}
                      disabled={listImage.length === MAX_IMAGE_ALLOW}
                    >
                      <span className={`${css.IconImage} ${css.IconButton}`} />
                    </button>
                    <input {...getInputProps()} />
                  </>
                )}
              </Dropzone>
            }
            children={
              <div className={`${css.Buttons} ${css.ButtonsTop}`}>
                <div>
                  <button
                    className={`${css.ButtonText} ${css.ButtonCancel}`}
                    title="Clear"
                    onClick={onEmpty}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    disabled={isLarge || !isShowCancelButton || !message}
                    className={`${css.Button} ${css.ButtonPublish}`}
                    title="Publish"
                    onClick={onPublish}
                  >
                    {isPublishing ? "Saving ..." : "Save"}
                  </button>
                </div>
              </div>
            }
          />
          {Boolean(errorImage) && (
            <div className={css.ErrorBox}>{errorImage}</div>
          )}
          {Boolean(listImage.length) && (
            <div className={css.ImageBox}>
              {listImage.map((image, index) => {
                const imageUrl =
                  typeof image === "string"
                    ? image
                    : URL.createObjectURL(image);
                return (
                  <div className={css.ImageItem} key={index}>
                    <div
                      className={css.ImageRemove}
                      onClick={() => removeImage(index)}
                    />
                    <img
                      src={imageUrl}
                      onClick={() => props.onShowModalImage(true, imageUrl)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {props.daysLeftToEdit ? (
            <div className={css.DayLeftNotice}>
              {`You have until ${props.daysLeftToEdit} from now to edit this comment.`}
            </div>
          ) : (
            ""
          )}
          <div className={css.ButtonsBottom}>
            <div>
              <button
                className={`${css.ButtonText} ${css.ButtonCancel}`}
                title="Clear"
                onClick={onEmpty}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                disabled={isLarge || !isShowCancelButton || !message}
                className={`${css.Button} ${css.ButtonPublish}`}
                title="Publish"
                onClick={onPublish}
              >
                {isPublishing ? "Saving ..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
