import { useCallback, useEffect, useMemo, useState } from 'react'
import { CommentTemplateData, GetPaginatedResponse, HandleList, SchnackAction } from "../../types";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { bookmarkNotificationList, deleteCommentList, deleteNotificationList, editComment, muteCommentList, postCommentAction, postReply, readNotificationList } from '../../api/requests';

export type ListParams = {
  readonly page: number
  readonly tab?: string
  readonly searchTerm?: string
  readonly sortBy: string | null
}

export type ModalImageControl = {
  show: boolean
  url: string
}

export type ParamsUpdater<T> = <K extends keyof T>(key: K, value: T[K]) => void

export type ListStateHook<T, P extends ListParams> = {
  readonly params: P
  readonly onPageChange: (page: number) => void
  readonly setActiveTab: (tab: string) => void
  readonly toggleAll: () => void
  readonly patchListParams: ParamsUpdater<P>
  readonly setListParams: (newParams: P) => void
  readonly onChangeSearch: (term: string) => void
  readonly resetState: () => P
  readonly select: (item: T) => void
  readonly selectedItems: ReadonlyArray<T>
  readonly items: ReadonlyArray<T>
  readonly pageCount: number
  readonly refetch: () => void
  readonly loading: boolean
  readonly allSelected: boolean
  readonly open: boolean
  readonly onChangeOpen: () => void
  readonly openEdit: boolean
  readonly onChangeOpenEdit: () => void
  readonly commentModal: CommentTemplateData
  readonly onChangeComment: (comment: CommentTemplateData) => void
  readonly handlePostReply: (comment: string, images: ReadonlyArray<string>) => void
  readonly handleEditComment: (comment: string, images: ReadonlyArray<string | File>) => void
  readonly handleMute: ({ ids, value, authorId }: HandleList) => void
  readonly handleRead: ({ ids, value, authorId }: HandleList) => void  
  readonly handleBookmark: ({ ids, value, authorId }: HandleList) => void
  readonly handleDelete: ({ ids, authorId }: HandleList) => void
  readonly handleOpenModal: (comment: CommentTemplateData, edit?: boolean) => void
  readonly handleCommentAction: (id: number, action: SchnackAction, userVote: string | undefined, authorId: number) => void
  readonly handleDeleteComments: (ids: ReadonlyArray<number>) => void
  readonly isRefreshCount: boolean
  readonly modalImageControl: ModalImageControl
  readonly handleModalImage: (show: boolean, url: string) => void
}

export type RequestParams<P> = Omit<P, 'page' | 'sortBy'> & { sortBy: string, page: number }

export const useListState = <T, P extends ListParams>(
  initState: P,
  query: (state: RequestParams<P>) => Promise<GetPaginatedResponse<T>>
): ListStateHook<T, P> => {
  const [listState, setListState] = useState(initState)
  const [selectedItems, setSelectedItems] = useState<ReadonlyArray<T>>([])
  const [isRefreshCount, setIsRefreshCount] = useState(false)
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [modalImageControl, setModalImageControl] = useState<ModalImageControl>({
    show: false,
    url: ''
  })
  const [commentModal, setCommentModal] = useState<CommentTemplateData>(null)

  const fetcher = useAsync<GetPaginatedResponse<T>>(() => {
    const { sortBy, page, ...rest } = listState;
    const params = {
      ...rest,
      sortBy: sortBy,
      page: page - 1
    }
    return query(params)
  }, [listState])

  const items = useMemo(() => {
    return fetcher.result?.rows || []
  }, [fetcher.result?.rows])

  const pageCount = useMemo(() => {
    return Math.ceil((fetcher.result?.count || 0) / 20)
  }, [fetcher.result?.count])

  const refetch = useCallback(() => {
    void fetcher.execute()
  }, [fetcher.loading])

  const handleModalImage = (show: boolean, url: string) => {
    setModalImageControl({show, url})
  }

  const setListParams = useCallback((update: P | ((prev: P) => P)) => {
    if (!fetcher.loading) {
      setListState(update)
    }
  }, [fetcher.loading])

  // Could've used Partial but Partial doesnt work well with generic types
  // https://github.com/microsoft/TypeScript/issues/13442
  const patchListParams = useCallback<ParamsUpdater<P>>((key, value) => {
    setListParams((prev) => ({ ...prev, [key]: value }))
  }, [fetcher.loading])

  const resetState = useCallback(() => {
    setListParams(initState)
    return initState
  }, [fetcher.loading])

  const onPageChange = useCallback((page: number) => {
    setListParams((prev) => ({ ...prev, page }))
  }, [fetcher.loading])

  const setActiveTab = useCallback((tab: string) => {
    setListParams((prev) => ({ ...prev, tab }))
  }, [fetcher.loading])

  const onChangeSearch = useCallback(
    (term: string) => {
      patchListParams('searchTerm', term)
    },
    [patchListParams]
  )

  const onChangeOpen = useCallback(
    () => {
      setOpen(!open)
    },
    [open]
  )

  const onChangeOpenEdit = useCallback(
    () => {
      setOpenEdit(!openEdit)
    },
    [openEdit]
  )

  const onChangeComment = useCallback(
    (comment: CommentTemplateData) => {
      setCommentModal(comment)
    },
    [open]
  )

  const handlePostReply = useCallback(async (comment: string, images: ReadonlyArray<string>) => {
    await postReply({
      comment,
      images,
      slug: commentModal.slug,
      replyTo: commentModal.replyTo ? commentModal.replyTo : commentModal.id,
      targetTo: commentModal.replyTo ? commentModal.id : null,
      authorId: commentModal.userId,
      title: commentModal.title
    })

    onChangeOpen()
    refetch()
  }, [commentModal])

  const handleEditComment = useCallback(async (comment: string, images: ReadonlyArray<string | File>) => {
    await editComment({
      id: commentModal.id,
      comment,
      images
    })

    onChangeOpenEdit()
    refetch()
  }, [commentModal])

  const allSelected = items.length ? (selectedItems.length === items.length) : false
  const toggleAll = useCallback((): void => {
    setSelectedItems(allSelected ? [] : items)
  }, [allSelected, items])

  const select = useCallback(
    (item: T): void => {
      setSelectedItems(
        selectedItems.includes(item)
          ? selectedItems.filter((i) => i !== item)
          : [...selectedItems, item]
      )
    },
    [selectedItems]
  )

  const performMuteAction = useAsyncCallback(muteCommentList)
  const performBookmarkAction = useAsyncCallback(bookmarkNotificationList)
  const performDeleteAction = useAsyncCallback(deleteNotificationList)
  const performDeleteCommentsAction = useAsyncCallback(deleteCommentList)
  const performCommentAction = useAsyncCallback(postCommentAction)
  const performReadAction = useAsyncCallback(readNotificationList)

  const onMute = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await performMuteAction.execute({ ids, value, authorId })
    refetch()
  }, [refetch])

  const handleMute = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await onMute({ ids, value, authorId })
  }, [onMute])

  const onRead = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await performReadAction.execute({ ids, value, authorId })
    setIsRefreshCount(!isRefreshCount)
    refetch()
  }, [refetch])

  const handleRead = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await onRead({ ids, value, authorId })
  }, [onRead])

  const onBookmark = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await performBookmarkAction.execute({ ids, value, authorId })
    refetch()
  }, [refetch])

  const handleBookmark = useCallback(async ({ ids, value, authorId }: HandleList) => {
    await onBookmark({ ids, value, authorId })
  }, [onBookmark])

  const onDelete = useCallback(async ({ ids, authorId }: HandleList) => {
    await performDeleteAction.execute({ ids, authorId })
    setIsRefreshCount(!isRefreshCount)
    refetch()
  }, [refetch])

  const handleDelete = useCallback(async ({ ids, authorId }: HandleList) => {
    await onDelete({ ids, authorId })
  }, [onDelete])

  const onDeleteComments = useCallback(async (ids: ReadonlyArray<number>) => {
    await performDeleteCommentsAction.execute(ids)
    refetch()
  }, [refetch])

  const handleDeleteComments = useCallback(async (ids: ReadonlyArray<number>) => {
    await onDeleteComments(ids)
  }, [onDeleteComments])

  const handleOpenModal = (comment: CommentTemplateData, edit?: boolean) => {
    onChangeComment(comment)
    if (edit) {
      onChangeOpenEdit()
    } else onChangeOpen()
  }

  const onCommentAction = useCallback(async (id: number, action: SchnackAction, authorId?: number) => {
    await performCommentAction.execute({ id, action, authorId })
    refetch()
  }, [refetch])
  
  const handleCommentAction = useCallback(async (id: number, action: SchnackAction, userVote: string | undefined, authorId: number) => {
    if (userVote === action) {
      return onCommentAction(id, 'unvote')
    }

    return await onCommentAction(id, action, authorId)
  }, [onCommentAction])

  useEffect(() => {
    setSelectedItems([])
  }, [items])

  return {
    params: listState,
    onPageChange,
    setActiveTab,
    select,
    allSelected,
    toggleAll,
    onChangeSearch,
    selectedItems,
    patchListParams,
    setListParams,
    items,
    pageCount,
    resetState,
    refetch,
    loading: fetcher.loading,
    open,
    onChangeOpen,
    openEdit,
    onChangeOpenEdit,
    commentModal,
    isRefreshCount,
    onChangeComment,
    handlePostReply,
    handleEditComment,
    handleMute,
    handleRead,
    handleBookmark,
    handleDelete,
    handleOpenModal,
    handleCommentAction,
    handleDeleteComments,
    modalImageControl,
    handleModalImage
  }
}
