import React, { useCallback, useState } from 'react'

type State = {
  readonly toolbarHeight: number
  readonly headerHeight: number
  readonly minimized: boolean
}

type Context = {
  readonly state: State
  readonly update: (patch: Partial<State>) => void
}

const initialState: State = {
  toolbarHeight: 0,
  headerHeight: 0,
  minimized: false,
}
export const DimensionsContext = React.createContext<Context>({
  state: initialState,
  update: () => null,
})

export const DimensionsProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State>(initialState)

  const update = useCallback(
    (patch: Partial<State>) => {
      setState({ ...state, ...patch })
    },
    [state]
  )

  return (
    <DimensionsContext.Provider value={{ state, update }}>
      {children}
    </DimensionsContext.Provider>
  )
}
